import { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { styled } from '@mui/material'
import Header from '../components/Header'
import MobileHeader from '../components/Header/MobileHeader'

import Polling from '../components/essential/Polling'
import Popups from '../components/essential/Popups'
import Web3ReactManager from '../components/essential/Web3ReactManager'
import Home from '../pages/home'
import WalletPage from '../pages/wallet'
import MobileWallet from '../pages/wallet/mobile'
import Stake from '../pages/stake'
import MintNFT from './mintNFT'
import MobileMintNFT from './mintNFT/mobile'

import Vault from './vault'
import MobileVault from './vault/mobile'
import MobileHome from '../pages/home/mobile'
import MobileStake from '../pages/stake/mobile'
import { ModalProvider } from 'context/ModalContext'
import { isMobile } from 'react-device-detect'
import { createI18n, I18nProvider } from 'react-simple-i18n'
import { langData } from '../langs/lang'
// import Footer from 'components/Footer'

const AppWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  overflowX: 'hidden',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    height: '100vh'
  }
}))

const ContentWrapper = styled('div')({
  width: '100%',
  maxHeight: '100vh',
  overflow: 'auto',
  alignItems: 'center'
})

const BodyWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: `calc(100vh - ${theme.height.header})`,
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    minHeight: `calc(100vh - ${theme.height.mobileHeader})`,
    marginTop: `-${theme.height.mobileHeader}`
  }
}))

export default function App() {
  return (
    <Suspense fallback={null}>
      <I18nProvider i18n={createI18n(langData, { lang: 'en' })}>
        <ModalProvider>
          <AppWrapper id="app">
            <ContentWrapper>
              {isMobile ? <MobileHeader /> : <Header />}
              <BodyWrapper id="body">
                <Popups />
                <Polling />
                <Web3ReactManager>
                  <Switch>
                    <Route exact strict path="/home" component={isMobile ? MobileHome : Home} />
                    <Route exact strict path="/mintNFT" component={isMobile ? MobileMintNFT : MintNFT} />
                    <Route exact strict path="/stake" component={isMobile ? MobileStake : Stake} />
                    <Route exact strict path="/vault" component={isMobile ? MobileVault : Vault} />
                    <Route exact strict path="/wallet" component={isMobile ? MobileWallet : WalletPage} />
                    <Route path="/vault/:inviter" component={isMobile ? MobileVault : Vault} />
                    <Route path="/">
                      <Redirect to="/home" />
                    </Route>
                  </Switch>
                </Web3ReactManager>
              </BodyWrapper>
              {/* <Footer /> */}
            </ContentWrapper>
          </AppWrapper>
        </ModalProvider>
      </I18nProvider>
    </Suspense>
  )
}
