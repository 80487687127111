import { Box, Button, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material'
import { useI18n } from 'react-simple-i18n'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.css'
import 'swiper/modules/autoplay/autoplay.min.css'
import Image from '../../components/Image'
import bannerImage from '../../assets/images/banner-img-m.png'
import ramBoxText from '../../assets/images/rambox-text.png'
import videoModal from '../../assets/images/video-modal.png'
import topText from '../../assets/images/top-text-m.png'
import topTextCn from '../../assets/images/top-text-cn.png'
import productItemBg from '../../assets/images/product-item-m.png'
import productItem1 from '../../assets/images/product-item-1.png'
import productItem2 from '../../assets/images/product-item-2.png'
import productItem3 from '../../assets/images/product-item-3.png'
import productArr from '../../assets/images/product_arr.png'
import modalLast from '../../assets/images/modal_last.png'
import modalNext from '../../assets/images/modal_next.png'
import fluentArrow from '../../assets/images/fluent_arrow.png'
import fluentOrganization from '../../assets/images/fluent_organization.png'
import solarCpu from '../../assets/images/solar_cpu.png'
import bottomBg from '../../assets/images/bottom-bg.png'
import ramBg from '../../assets/images/ram-bg.png'
import ramICon1 from '../../assets/images/ram-icon-1.png'
import ramICon2 from '../../assets/images/ram-icon-2.png'
import ramICon3 from '../../assets/images/ram-icon-3.png'
import ramICon4 from '../../assets/images/ram-icon-4.png'
import NFTfiVaultImg from '../../assets/images/NFTfiVaultImg.png'
import networkIcon1 from '../../assets/images/network-icon-1.png'
import networkIcon2 from '../../assets/images/network-icon-2.png'
import networkIcon3 from '../../assets/images/network-icon-3.png'
import networkIcon4 from '../../assets/images/network-icon-4.png'
import topImage from '../../assets/images/top-img.png'
import topBG from '../../assets/images/top-bg-m.png'
import modalTitleBg from '../../assets/images/modal-title-bg-m.png'
import { NavLink } from 'react-router-dom'
import { ExternalLink } from '../../theme/components'
import twitter from '../../assets/images/twitter_black.png'
import telegram from '../../assets/images/telegram_black.png'
import discord from '../../assets/images/discord_black.png'
import { useState } from 'react'
import './index.css'
import { CurrencyAmount } from '../../constants/token'
import { useStaking } from '../../hooks/useStaking'
import JSBI from 'jsbi'
const TopFrame = styled('div')`
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${topBG});
  background-size: 100%;
  background-repeat: no-repeat;
`

const Title = styled(Typography)`
  font-size: 40px;
  font-family: 'Kanit';
  font-style: italic;
  color: #000;
  font-weight: 700;
  line-height: 50px;
`

const BuyButton = styled(Button)(({ theme }) => ({
  width: 193,
  height: 40,
  background: theme.palette.primary.main,
  color: theme.palette.text.primary,
  fontSize: 14,
  marginTop: 35,
  border: '1px solid ',
  borderColor: theme.palette.primary.main
}))

const GetButton = styled(Button)(({ theme }) => ({
  width: 224,
  height: 50,
  background: theme.palette.primary.main,
  color: theme.palette.text.primary,
  fontSize: 14,
  border: '1px solid ',
  borderColor: theme.palette.primary.main
}))

function SelectProductItem({ productItemIndex }: { productItemIndex: number }) {
  switch (productItemIndex) {
    case 0:
      return <NFTfiVault />
    case 1:
      return <ContributorNFT />
    case 2:
      return <RamboxNetwork />
    default:
      return <></>
  }
}

function NFTfiVault() {
  const { t } = useI18n()
  return (
    <>
      <Stack
        marginBottom={30}
        direction={'column'}
        spacing={20}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
      >
        <Box
          sx={{
            margin: '0 auto'
          }}
        >
          <Image width={200} src={NFTfiVaultImg} />
        </Box>
        <Typography fontSize={16} lineHeight={'32px'} color={'#000'}>
          {t('productDesc1')}
        </Typography>
      </Stack>
      <Box
        sx={{
          margin: '0 auto',
          textAlign: 'center'
        }}
      >
        <NavLink style={{ textDecoration: 'none' }} to={'/vault'}>
          <Button
            style={{
              background: '#fef13f',
              borderRadius: '10px',
              padding: '15px 20px',
              lineHeight: '20px',
              color: '#000',
              fontSize: 14
            }}
          >
            {t('gotovault')}
          </Button>
        </NavLink>
      </Box>
    </>
  )
}
function ContributorNFT() {
  const { t } = useI18n()
  return (
    <>
      <Stack marginBottom={30} direction={'column'} spacing={16} alignItems={'center'} justifyContent={'space-between'}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          width={280}
        >
          <Image width={45} height={45} src={solarCpu} />
          <Typography
            height={40}
            fontWeight={700}
            color={'#000'}
            fontSize={24}
            lineHeight={'32px'}
            textAlign={'center'}
            mt={14}
          >
            {t('prod2_1')}
          </Typography>
          <Typography lineHeight={'32px'} textAlign={'center'} mt={10} fontSize={16} fontWeight={400} color={'#000'}>
            {t('prod2_1_desc')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          width={280}
        >
          <Image width={45} height={45} src={fluentArrow} />
          <Typography
            height={40}
            fontWeight={700}
            color={'#000'}
            fontSize={24}
            lineHeight={'32px'}
            textAlign={'center'}
            mt={14}
          >
            {t('prod2_2')}
          </Typography>
          <Typography lineHeight={'32px'} textAlign={'center'} mt={10} fontSize={16} fontWeight={400} color={'#000'}>
            {t('prod2_2_desc')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          width={280}
        >
          <Image width={45} height={45} src={fluentOrganization} />
          <Typography
            height={40}
            fontWeight={700}
            color={'#000'}
            fontSize={24}
            lineHeight={'32px'}
            textAlign={'center'}
            mt={14}
          >
            {t('prod2_3')}
          </Typography>
          <Typography lineHeight={'32px'} textAlign={'center'} mt={10} fontSize={16} fontWeight={400} color={'#000'}>
            {t('prod2_3_desc')}{' '}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          width={280}
        >
          <Image width={45} height={45} src={fluentOrganization} />
          <Typography
            height={40}
            fontWeight={700}
            color={'#000'}
            fontSize={24}
            lineHeight={'32px'}
            textAlign={'center'}
            mt={14}
          >
            {t('prod2_4')}
          </Typography>
          <Typography lineHeight={'32px'} textAlign={'center'} mt={10} fontSize={16} fontWeight={400} color={'#000'}>
            {t('prod2_4_desc')}{' '}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          width={280}
        >
          <Image width={45} height={45} src={fluentOrganization} />
          <Typography
            height={40}
            fontWeight={700}
            color={'#000'}
            fontSize={24}
            lineHeight={'32px'}
            textAlign={'center'}
            mt={14}
          >
            {t('prod2_5')}
          </Typography>
          <Typography lineHeight={'32px'} textAlign={'center'} mt={10} fontSize={16} fontWeight={400} color={'#000'}>
            {t('prod2_5_desc')}{' '}
          </Typography>
        </Box>
      </Stack>
      <Box
        sx={{
          margin: '0 auto',
          textAlign: 'center'
        }}
      >
        <NavLink style={{ textDecoration: 'none' }} to={'/mintNFT'}>
          <Button
            style={{
              background: '#fef13f',
              borderRadius: '10px',
              padding: '15px 20px',
              lineHeight: '20px',
              color: '#000',
              fontSize: 14
            }}
          >
            MINT NFT
          </Button>
        </NavLink>
      </Box>
    </>
  )
}
function RamboxNetwork() {
  const { t } = useI18n()
  return (
    <>
      <Stack marginBottom={30} direction={'column'} spacing={16} alignItems={'center'} justifyContent={'space-between'}>
        <Typography fontSize={16} lineHeight={'32px'} color={'#000'}>
          {t('productDesc3')}
        </Typography>
        <Stack width={'100%'} direction={'row'} spacing={28}>
          <Box
            sx={{
              width: 'calc(100%/2)',
              height: 126,
              border: '2px solid #000000',
              padding: '19px 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Image width={54} height={54} src={networkIcon1}></Image>
            <Typography fontWeight={700} fontSize={19} lineHeight={'26px'} color={'#000'} textAlign={'center'}>
              {t('prod3_1')}
            </Typography>
          </Box>
          <Box
            sx={{
              width: 'calc(100%/2)',
              height: 126,
              border: '2px solid #000000',
              padding: '19px 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Image width={54} height={54} src={networkIcon2}></Image>
            <Typography fontWeight={700} fontSize={19} lineHeight={'26px'} color={'#000'} textAlign={'center'}>
              {t('prod3_2')}
            </Typography>
          </Box>
        </Stack>
        <Stack width={'100%'} direction={'row'} spacing={28}>
          <Box
            sx={{
              width: 'calc(100%/2)',
              height: 126,
              border: '2px solid #000000',
              padding: '19px 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Image width={54} height={54} src={networkIcon3}></Image>
            <Typography fontWeight={700} fontSize={19} lineHeight={'26px'} color={'#000'} textAlign={'center'}>
              {t('prod3_3')}
            </Typography>
          </Box>
          <Box
            sx={{
              width: 'calc(100%/2)',
              height: 126,
              border: '2px solid #000000',
              padding: '19px 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Image width={54} height={54} src={networkIcon4}></Image>
            <Typography fontWeight={700} fontSize={19} lineHeight={'26px'} color={'#000'} textAlign={'center'}>
              {t('prod3_4')}
            </Typography>
          </Box>
        </Stack>
      </Stack>
      <Box
        sx={{
          margin: '0 auto',
          textAlign: 'center'
        }}
      >
        <Button
          style={{
            background: '#E3E3E3',
            borderRadius: '10px',
            padding: '15px 20px',
            lineHeight: '20px',
            color: '#000',
            fontSize: 14
          }}
        >
          {t('comingSoon')}
        </Button>
      </Box>
    </>
  )
}
function ProductModalsMain({ index, actionFun, isOpen }: { index: number; actionFun: any; isOpen: any }) {
  const { t } = useI18n()
  const titleList = [
    {
      title: t('NFTfiVault'),
      icon: ''
    },
    {
      title: t('ContributorNFT'),
      icon: ''
    },
    {
      title: t('RamboxNetwork'),
      icon: ''
    }
  ]
  const changePage = (action: string) => {
    if (action === 'last') {
      if (index === 0) {
        actionFun(2)
      } else {
        actionFun(index - 1)
      }
    }

    if (action === 'next') {
      if (index === 2) {
        actionFun(0)
      } else {
        actionFun(index + 1)
      }
    }
  }
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        minHeight: '100vh',
        background: '#fff',
        zIndex: 9999,
        padding: '64px 25px',
        overflow: 'scroll',
        height: '100%',
        marginTop: '0!important'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          right: '25px',
          top: '25px',
          zIndex: 20
        }}
        onClick={() => {
          isOpen(false)
        }}
      >
        <Typography fontSize={24} lineHeight={'30px'}>
          Close
        </Typography>
      </Box>
      <Stack marginBottom={44} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Box
          sx={{
            cursor: 'pointer'
          }}
        >
          <Image width={22} height={22} src={modalLast} onClick={() => changePage('last')} />
        </Box>
        <Box
          sx={{
            width: 238,
            height: 38,
            background: `url(${modalTitleBg})`,
            lineHeight: '38px',
            textAlign: 'center'
          }}
        >
          {titleList[index].title}
        </Box>
        <Box
          sx={{
            cursor: 'pointer'
          }}
        >
          <Image width={22} height={22} src={modalNext} onClick={() => changePage('next')} />
        </Box>
      </Stack>
      <SelectProductItem productItemIndex={index} />
    </Box>
  )
}
function ProductItem({ title, icon, index }: { title: string; icon: string; index: number }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEnter, setIsEnter] = useState(false)
  const [modalIndex, setModalIndex] = useState(0)
  console.log(isEnter)

  function openModal(index: number) {
    setModalIndex(index)
    setIsModalOpen(true)
  }
  function setEnterItem(isEnter: boolean) {
    setIsEnter(isEnter)
  }

  return (
    <>
      {isModalOpen && <ProductModalsMain isOpen={setIsModalOpen} actionFun={setModalIndex} index={modalIndex} />}
      <Stack
        sx={{
          width: '100%',
          height: 55,
          color: '#000',
          cursor: 'pointer',
          position: 'relative'
        }}
        onMouseEnter={() => setEnterItem(true)}
        onMouseLeave={() => setEnterItem(false)}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        onClick={() => openModal(index)}
      >
        <Box
          sx={{
            position: 'absolute',
            right: 20,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 11
          }}
        >
          <Image width={23} src={productArr} />
        </Box>
        <Stack
          sx={{
            position: 'relative',
            zIndex: 13,
            width: '100%'
          }}
          direction={'row'}
          alignItems={'center'}
        >
          <Box marginLeft={20}>
            <Image width={23} height={23} src={icon} />
          </Box>
          <Typography ml={20} width={320} fontSize={18} lineHeight={'55px'}>
            {title}
          </Typography>
        </Stack>
        <Box
          sx={{
            position: 'absolute',
            zIndex: 10,
            width: '100%',
            height: 55,
            left: 0,
            top: 0,
            transitionDuration: '1s',
            '&:hover': {
              transform: 'scaleX(1.1)'
            }
          }}
        >
          <Image width={'100%'} height={55} src={productItemBg} />
        </Box>
      </Stack>
    </>
  )
}

// const banners = [topBG]
const MainLogoPage = styled(NavLink)(({ theme }) => ({
  position: 'absolute',
  left: 20,
  top: -3,
  '& img': {
    width: 180,
    height: 112
  },
  '&:hover': {
    cursor: 'pointer'
  },
  [theme.breakpoints.down('sm')]: {
    '& img': { width: 137, height: 'auto' },
    marginBottom: -10
  }
}))
export default function Home() {
  const { apy } = useStaking()
  const { t, i18n } = useI18n()
  const logoList = [
    require('../../assets/images/logo-28.png'),
    require('../../assets/images/logo-1.png'),
    require('../../assets/images/logo-2.png'),
    require('../../assets/images/logo-3.png'),
    require('../../assets/images/logo-4.png'),
    require('../../assets/images/logo-5.png'),
    require('../../assets/images/logo-6.png'),
    require('../../assets/images/logo-7.png'),
    require('../../assets/images/logo-9.png'),
    require('../../assets/images/logo-10.png'),
    require('../../assets/images/logo-11.png'),
    require('../../assets/images/logo-12.png'),
    require('../../assets/images/logo-13.png'),
    require('../../assets/images/logo-14.png'),
    require('../../assets/images/logo-16.png'),
    require('../../assets/images/logo-17.png'),
    require('../../assets/images/logo-18.png'),
    require('../../assets/images/logo-19.png'),
    require('../../assets/images/logo-20.png'),
    require('../../assets/images/logo-21.png'),
    require('../../assets/images/logo-22.png'),
    require('../../assets/images/logo-23.png'),
    require('../../assets/images/logo-24.png'),
    require('../../assets/images/logo-25.png'),
    require('../../assets/images/logo-26.png'),
    require('../../assets/images/logo-27.png')
  ]
  const list = [
    [t('roadMapV2.r1_1'), t('roadMapV2.r1_2'), t('roadMapV2.r1_3')],
    [t('roadMapV2.r2_1'), t('roadMapV2.r2_2')],
    [t('roadMapV2.r3_1'), t('roadMapV2.r3_2'), t('roadMapV2.r3_3')],
    [t('roadMapV2.r4_1'), t('roadMapV2.r4_2')],
    [t('roadMapV2.r5_1'), t('roadMapV2.r5_2')],
    [
      t('roadMapV2.r6_1'),
      t('roadMapV2.r6_2'),
      t('roadMapV2.r6_3'),
      t('roadMapV2.r6_4'),
      t('roadMapV2.r6_5'),
      t('roadMapV2.r6_6'),
      t('roadMapV2.r6_7')
    ],
    [t('roadMapV2.r7_1'), t('roadMapV2.r7_2'), t('roadMapV2.r7_3')],
    [t('roadMapV2.r8_1'), t('roadMapV2.r8_2'), t('roadMapV2.r8_3')]
  ]

  const titleList = [
    t('roadMapV2.r1'),
    t('roadMapV2.r2'),
    t('roadMapV2.r3'),
    t('roadMapV2.r4'),
    t('roadMapV2.r5'),
    t('roadMapV2.r6'),
    t('roadMapV2.r7'),
    t('roadMapV2.r8')
  ]

  return (
    <Box p={0} width={'100%'}>
      <MainLogoPage id={'/'} to={'/'}>
        <Image src={topImage} alt={'chainswap'} />
      </MainLogoPage>

      <TopFrame>
        <Stack
          sx={{
            background: '#000'
          }}
          height={585}
        >
          <Box sx={{ position: 'absolute', top: 149, right: 0, zIndex: 9 }}>
            <Image width={317} src={bannerImage} />
          </Box>
        </Stack>

        <Box sx={{ position: 'absolute', top: 0, zIndex: 10, marginTop: 254, padding: '0 25px' }}>
          <Box
            sx={{
              display: 'flex',
              minWidth: '100%',
              padding: '0',
              flexDirection: 'column',
              alignItems: 'flex-start',
              borderRadius: '20px',
              justifyContent: 'center',
              zIndex: 10
            }}
          >
            {i18n.getLang() === 'cn' ? (
              <Image width={'100%'} src={topTextCn} />
            ) : (
              <Image width={'100%'} src={topText} />
            )}

            <NavLink style={{ textDecoration: 'none' }} to={'/vault'}>
              <BuyButton>{t('entervault')}</BuyButton>
            </NavLink>
          </Box>
        </Box>
      </TopFrame>

      <Stack
        sx={{
          padding: '0 25px'
        }}
        height={35}
        width={'100%'}
        mt={0}
        direction={'row'}
      >
        <Typography fontWeight={400} color={'#000'} fontSize={10} lineHeight={'35px'}>
          {t('CSA')}
        </Typography>
        <Typography fontWeight={400} color={'#000'} fontSize={16} lineHeight={'35px'}>
          {apy ? CurrencyAmount.ether(JSBI.multiply(JSBI.BigInt(apy), JSBI.BigInt('100'))).toFixed(0) : '--'} %
        </Typography>
      </Stack>
      <Stack
        sx={{
          padding: '0 25px'
        }}
        height={35}
        width={'100%'}
        mt={20}
        spacing={20}
        direction={'row'}
      >
        <ExternalLink href="https://discord.gg/zCvzPYgGWj">
          <Image width={34} src={discord} />
        </ExternalLink>
        <ExternalLink href="https://t.me/RamBox">
          <Image width={34} src={telegram} />
        </ExternalLink>
        <ExternalLink href="https://twitter.com/0xRamBox">
          <Image width={34} src={twitter} />
        </ExternalLink>
      </Stack>
      <Box
        width={'100%'}
        sx={{ display: 'flex', margin: '70px auto 0', flexDirection: 'column', position: 'relative', zIndex: 10 }}
      >
        {/* <Title style={{ marginTop: 117, marginBottom: 30 }}>{t('home.video')}</Title> */}
        <Stack position={'relative'} width={'100%'} padding={'0 25px'} height={211} margin={'auto'}>
          <video
            muted
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
              width: '365px',
              height: '211px',
              objectFit: 'fill',
              padding: '16px 9px 8px 12px',
              backgroundImage: `url(${videoModal})`,
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat'
            }}
            preload="auto"
            loop
            controls
            x5-video-player-type="h5"
          >
            <source src={'https://backend.ramx.tech/download/rambox.mp4'} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        </Stack>
        <Stack width={'100%'} height={'auto'} margin={'35px auto 35px'} padding={'0 25px'}>
          <Typography
            textAlign={'center'}
            fontWeight={700}
            fontSize={38}
            lineHeight={'50px'}
            color={'#000'}
            fontStyle={'italic'}
          >
            {t('romBox_title')}
          </Typography>
          <Typography
            zIndex={2}
            width={'100%'}
            fontWeight={400}
            fontSize={16}
            color={'#000'}
            lineHeight={'32px'}
            marginTop={22}
          >
            {t('romBox_desc')}
          </Typography>

          <Box sx={{ width: '100%', position: 'absolute', zIndex: 1, left: 0, bottom: 0 }}>
            <Image width={'100%'} src={ramBoxText} />
          </Box>
        </Stack>
      </Box>

      <Box sx={{ width: '100%', position: 'relative', padding: '70px 0 70px' }}>
        <Box sx={{ width: '100%', margin: '0px 0 42px 0' }}>
          <Title textAlign={'center'}>{'PRODUCTS'}</Title>
        </Box>

        <Stack paddingRight={25} direction={'column'} spacing={26}>
          <ProductItem index={0} title={t('NFTfiVault')} icon={productItem1}></ProductItem>
          <ProductItem index={1} title={t('ContributorNFT')} icon={productItem2}></ProductItem>
          <ProductItem index={2} title={t('RamboxNetwork')} icon={productItem3}></ProductItem>
        </Stack>
      </Box>

      {/* $RAM IS THE DRIVING FORCE OF RAMBOX ECOSYSTEM */}
      <Box
        sx={{
          background: `url(${ramBg})`,
          backgroundSize: '100%',
          padding: '79px 25px'
        }}
      >
        <Box
          sx={{
            margin: '0 auto 82px',
            width: '100%'
          }}
        >
          <Typography
            fontSize={36}
            lineHeight={'50px'}
            textAlign={'center'}
            color={'#fff'}
            fontWeight={700}
            fontStyle={'italic'}
            fontFamily={'Kanit'}
          >
            <Typography
              fontSize={36}
              lineHeight={'50px'}
              textAlign={'center'}
              color={'#FEF13F'}
              fontWeight={700}
              fontStyle={'italic'}
              fontFamily={'Kanit'}
              mr={'.25em'}
              sx={{
                display: 'inline'
              }}
            >
              $RAM
            </Typography>
            {t('ramIs')}
          </Typography>
          <Typography
            fontSize={36}
            lineHeight={'50px'}
            textAlign={'center'}
            color={'#fff'}
            fontWeight={700}
            fontStyle={'italic'}
            fontFamily={'Kanit'}
          >
            {t('ramIs_2')}
          </Typography>
        </Box>
        <Stack direction={'row'} width={'100%'} margin={'0 auto 68px'} justifyContent={'space-between'}>
          <Box
            sx={{
              width: '50%'
            }}
          >
            <Box
              sx={{
                width: 79,
                margin: '0 auto 27px'
              }}
            >
              <Image width={79} src={ramICon1}></Image>
            </Box>
            <Typography fontFamily={'Kanit'} fontSize={20} lineHeight={'25px'} color={'#fff'} textAlign={'center'}>
              {t('ramIs_desc_1')}
            </Typography>
          </Box>

          <Box
            sx={{
              width: '50%'
            }}
          >
            <Box
              sx={{
                width: 79,
                margin: '0 auto 27px'
              }}
            >
              <Image width={79} src={ramICon2}></Image>
            </Box>
            <Typography fontFamily={'Kanit'} fontSize={20} lineHeight={'25px'} color={'#fff'} textAlign={'center'}>
              {t('ramIs_desc_2')}
            </Typography>
          </Box>
        </Stack>
        <Stack direction={'row'} width={'100%'} margin={'0 auto 68px'} justifyContent={'space-between'}>
          <Box
            sx={{
              width: '50%'
            }}
          >
            <Box
              sx={{
                width: 79,
                margin: '0 auto 27px'
              }}
            >
              <Image width={79} src={ramICon3}></Image>
            </Box>
            <Typography fontFamily={'Kanit'} fontSize={20} lineHeight={'25px'} color={'#fff'} textAlign={'center'}>
              {t('ramIs_desc_3')}
            </Typography>
          </Box>

          <Box
            sx={{
              width: '50%'
            }}
          >
            <Box
              sx={{
                width: 79,
                margin: '0 auto 27px'
              }}
            >
              <Image width={79} src={ramICon4}></Image>
            </Box>
            <Typography fontFamily={'Kanit'} fontSize={20} lineHeight={'25px'} color={'#fff'} textAlign={'center'}>
              {t('ramIs_desc_4')}
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{
            width: 224,
            height: 50,
            margin: '0 auto'
          }}
        >
          <ExternalLink href="https://www.mexc.com/exchange/RAM_USDT" style={{ textDecoration: 'none' }}>
            <GetButton>{'GET $RAM NOW'}</GetButton>
          </ExternalLink>
        </Box>
      </Box>
      {/* roadMap */}
      <Box marginTop={77} paddingBottom={73}>
        <Box
          sx={{
            margin: '0 auto',
            width: 322
          }}
        >
          <Title textAlign={'center'} id={'roadmap'}>
            {t('roadmap.title')}
          </Title>
        </Box>
        <Stack
          margin={'auto'}
          mt={60}
          padding={'0 25px'}
          width={'100%'}
          justifyContent={'space-between'}
          direction={'row'}
          sx={{
            flexWrap: 'wrap'
          }}
        >
          {list.map((item, index) => {
            return (
              <Box
                sx={{
                  border: '2px solid #000000',
                  boxShadow: '-4px 4px 0px #FEF13F',
                  padding: '27px 12px',
                  width: '100%',
                  height: 'auto'
                }}
                key={index + 's'}
                mb={25}
              >
                <Typography
                  marginBottom={13}
                  textAlign={'center'}
                  fontFamily={'Kanit'}
                  fontWeight={700}
                  fontSize={24}
                  color={'#000'}
                >
                  {titleList[index]}
                </Typography>
                <Stack width={'100%'} spacing={5} key={index}>
                  {item.map(subItem => {
                    return (
                      <Typography
                        sx={{
                          '&::before': {
                            content: '"."',
                            fontSize: 42,
                            color: '#000',
                            lineHeight: 0.2,
                            position: 'absolute',
                            marginTop: -8,
                            marginLeft: -16,
                            fontFamily: 'Roboto !important'
                          }
                        }}
                        paddingRight={12}
                        paddingLeft={16}
                        lineHeight={'25px'}
                        fontSize={14}
                        key={index + 's'}
                      >
                        {subItem}
                      </Typography>
                    )
                  })}
                </Stack>
              </Box>
            )
          })}
        </Stack>
      </Box>
      {/* Investors & Partners */}
      <Box
        sx={{
          background: `url(${bottomBg})`,
          paddingTop: 45,
          paddingBottom: 43,
          backgroundSize: '100%',
          paddingLeft: 25,
          paddingRight: 25
        }}
      >
        <Box
          sx={{
            margin: '0 auto 82px',
            width: '100%'
          }}
        >
          <Title textAlign={'center'}>{t('InPa')}</Title>
        </Box>
        <Stack
          sx={{
            flexWrap: 'wrap'
          }}
          direction={'row'}
          justifyContent={'flex-start'}
          // spacing={28}
          width={'100%'}
          margin={'0 auto'}
        >
          {logoList.map((item, index) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
                width={'50%'}
                key={index}
                mb={20}
              >
                <Image width={'auto'} height={55} src={item.default} />
              </Box>
            )
          })}
        </Stack>

        <Typography fontSize={16} lineHeight={'32px'} mt={41} textAlign={'center'} color={'#000'}>
          Copyright 2023 - RamBox All right reserved
        </Typography>
      </Box>
    </Box>
  )
}
