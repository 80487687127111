import { Stack, styled, Typography, Box } from '@mui/material'
// import Web3 from 'web3'
// import { HideOnMobile, ShowOnMobile } from 'theme/index'
import { ShowOnMobile } from 'theme/index'
import ActionButton from '../../components/Button/ActionButton'
import NumericalInput from '../../components/Input/InputNumerical'
import { useTokenBalance } from '../../state/wallet/hooks'
import { RAM, STAKING_ADDRESS, ZERO_ADDRESS } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import { useCallback, useMemo, useState, useEffect } from 'react'
import useModal from '../../hooks/useModal'
import TransactionPendingModal from 'components/Modal/TransactionModals/TransactionPendingModal'
import TransactionSubmittedModal from '../../components/Modal/TransactionModals/TransactiontionSubmittedModal'
import MessageBox from '../../components/Modal/TransactionModals/MessageBox'
import { tryParseAmount } from '../../utils/parseAmount'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { isMobile } from 'react-device-detect'
import { CurrencyAmount, TokenAmount } from '../../constants/token'
import topStoryBackimg from '../../assets/images/top_story_backimg.png'
import Image from '../../components/Image'
import RAMIcoin from '../../assets/images/ram-coin.png'
import Coins from '../../assets/images/coins.png'
import vaultLogo from '../../assets/images/vault-logo.png'
import lockIcon from '../../assets/images/lock-icon.png'
import valutBgText from '../../assets/images/vault-bg-text.png'
import valutBgLine from '../../assets/images/vault-bg-line.png'
import { useI18n } from 'react-simple-i18n'
import Button from 'components/Button/Button'
import { useAvailableAddress, useStaking } from '../../hooks/useStaking'
import { usePancakeswap } from '../../hooks/usePancakeswap'
import { ExternalLink } from '../../theme/components'

import BindModal from '../../components/Modal/TransactionModals/BindModal'
import Input from '../../components/Input'
import JSBI from 'jsbi'
import { shortenText, shortenAddress } from '../../utils'
// import { shortenAddress, shortenText } from '../../utils'
import { useParams } from 'react-router-dom'
import Copy from '../../components/essential/Copy'
import { useUserHasSubmittedClaim } from '../../state/transactions/hooks'
// import { Star } from '@mui/icons-material'
import { Axios } from '../../utils/axios'
import usePolling from '../../hooks/usePolling'

const IDOFrame = styled('div')`
  height: auto;
  background-image: url(${topStoryBackimg});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  padding-top: 149px;
  min-height: 100vh;
`
const Title = styled(Typography)`
  font-size: 64px;
  font-family: 'Kanit';
  font-style: italic;
  color: #000;
  font-weight: 700;
  margin-right: auto;
  margin-bottom: 28px;
`

export default function Staking() {
  const params = useParams<{ inviter: string }>()
  const { t } = useI18n()
  const { account, chainId } = useActiveWeb3React()
  const [typed, setTyped] = useState('')
  const [isDeposit, setIsDeposit] = useState(true)
  const { showModal, hideModal } = useModal()
  const {
    stake,
    unstake,
    stakedAmount,
    contribution,
    register,
    refAddress,
    claim,
    exit,
    stakedReward,
    contributionReward,
    apy,
    totalStaked
  } = useStaking()
  const { amounts } = usePancakeswap()

  const [apiRamPrice, setApiRamPrice] = useState('--')
  function calculateRamPrice(amounts: any) {
    if (amounts) {
      return (amounts[1].toString() / amounts[0].toString()).toFixed(4)
    } else {
      return '--'
    }
  }

  const ramPrice = amounts ? calculateRamPrice(amounts) : '--'
  const amounts2Usdt =
    ramPrice && ramPrice != '--'
      ? ` ${Number(
          (Number(ramPrice) * Number(CurrencyAmount.ether(totalStaked).toSignificant(9))).toFixed(0)
        ).toLocaleString()}`
      : '--'

  const stakedBalance = stakedAmount ? new TokenAmount(RAM[chainId ?? 56], JSBI.BigInt(stakedAmount)) : undefined
  const ramBalance = useTokenBalance(account ?? undefined, RAM[chainId ?? 56])
  const balanceAmount = isDeposit ? ramBalance : stakedBalance
  const inputAmount = tryParseAmount(typed, RAM[chainId ?? 56]) as TokenAmount | undefined
  const [approvalState, approveCallback] = useApproveCallback(inputAmount, STAKING_ADDRESS[chainId ?? 56])
  const [destinationAddress, setDestinationAddress] = useState(params.inviter)
  const [isBindOpen, setIsBindOpen] = useState(false)
  const { able, loading } = useAvailableAddress(destinationAddress)
  const enoughAsset =
    balanceAmount && inputAmount && (balanceAmount.greaterThan(inputAmount) || balanceAmount.equalTo(inputAmount))
  const { claimSubmitted: binding } = useUserHasSubmittedClaim(`${account}_bind` ?? undefined)
  const stakeCallback = useCallback(async () => {
    if (!typed || !account || !inputAmount) return
    showModal(<TransactionPendingModal />)
    stake(inputAmount)
      .then(() => {
        hideModal()
        showModal(<TransactionSubmittedModal />)
      })
      .catch((err: any) => {
        hideModal()
        showModal(
          <MessageBox type="error">{err.error && err.error.message ? err.error.message : err?.message}</MessageBox>
        )
        console.error(err)
      })
  }, [typed, account, inputAmount, showModal, stake, hideModal])

  const unstakeCallback = useCallback(async () => {
    if (!typed || !account || !inputAmount) return
    showModal(<TransactionPendingModal />)
    unstake(inputAmount)
      .then(() => {
        hideModal()
        showModal(<TransactionSubmittedModal />)
      })
      .catch((err: any) => {
        hideModal()
        showModal(
          <MessageBox type="error">{err.error && err.error.message ? err.error.message : err?.message}</MessageBox>
        )
        console.error(err)
      })
  }, [typed, account, inputAmount, showModal, unstake, hideModal])

  const bindCallback = useCallback(async () => {
    if (!account) return
    showModal(<TransactionPendingModal />)
    register(destinationAddress)
      .then(() => {
        hideModal()
        showModal(<TransactionSubmittedModal />)
      })
      .catch((err: any) => {
        hideModal()
        showModal(
          <MessageBox type="error">{err.error && err.error.message ? err.error.message : err?.message}</MessageBox>
        )
        console.error(err)
      })
  }, [account, showModal, register, destinationAddress, hideModal])

  useEffect(() => {
    if (refAddress === ZERO_ADDRESS) {
      setIsBindOpen(true)
    } else {
      setIsBindOpen(false)
    }
  }, [refAddress])

  const prePoling = () => {
    return Axios.get('/price')
      .then(res => {
        if (res.data) {
          setApiRamPrice(res.data?.price)
        }
        return true
      })
      .catch(() => {
        return false
      })
  }

  const { doPolling } = usePolling(prePoling)

  useEffect(() => {
    doPolling()
  }, [doPolling])

  const claimCallback = useCallback(async () => {
    if (!account) return
    showModal(<TransactionPendingModal />)
    claim()
      .then(() => {
        hideModal()
        showModal(<TransactionSubmittedModal />)
      })
      .catch((err: any) => {
        hideModal()
        showModal(
          <MessageBox type="error">{err.error && err.error.message ? err.error.message : err?.message}</MessageBox>
        )
        console.error(err)
      })
  }, [account, showModal, claim, hideModal])

  const exitCallback = useCallback(async () => {
    if (!account) return
    showModal(<TransactionPendingModal />)
    exit()
      .then(() => {
        hideModal()
        showModal(<TransactionSubmittedModal />)
      })
      .catch((err: any) => {
        hideModal()
        showModal(
          <MessageBox type="error">{err.error && err.error.message ? err.error.message : err?.message}</MessageBox>
        )
        console.error(err)
      })
  }, [account, showModal, exit, hideModal])

  const mintDisable = useMemo(() => {
    return !inputAmount
  }, [inputAmount])

  return (
    <IDOFrame sx={{ width: isMobile ? '100%' : '1248px' }}>
      <BindModal isOpen={isBindOpen} onDismiss={() => setIsBindOpen(false)}>
        <Stack spacing={30} alignItems={'center'}>
          <Image style={{ borderRadius: '100%' }} width={68} src={RAMIcoin} />
          <Input
            placeholder={t('enterInviter')}
            value={destinationAddress}
            onChange={e => setDestinationAddress(e.target.value)}
          />
          <ActionButton
            pending={loading || binding}
            pendingText={'Loading'}
            disableAction={!able || refAddress !== ZERO_ADDRESS}
            onAction={bindCallback}
            actionText={able ? t('bind') : t('notInviter')}
          />
        </Stack>
      </BindModal>
      <Title>VAULT</Title>
      <Stack
        sx={{
          position: 'relative',
          zIndex: 10
        }}
        direction={'row'}
        justifyContent={'space-between'}
        width={'100%'}
        spacing={20}
      >
        <Stack padding={'24px 20px'} height={264} width={461} border={'2px solid #000'}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Stack direction={'row'} spacing={10}>
              <Image width={34} height={34} src={vaultLogo}></Image>
              <Typography fontWeight={700} fontSize={24} lineHeight={'32px'}>
                $RAM
              </Typography>
            </Stack>

            <ExternalLink href="https://www.mexc.com/exchange/RAM_USDT" style={{ textDecoration: 'none' }}>
              {' '}
              <Button
                style={{
                  border: '2px solid #000',
                  background: 'transparent'
                }}
                fontSize={12}
                width="152px"
                height="40px"
              >
                {t('get')} $RAM
              </Button>
            </ExternalLink>
          </Stack>
          <Stack mt={62} mb={30} direction={'row'} alignItems={'center'} justifyContent={'space-around'}>
            <Stack>
              <Typography fontSize={16} lineHeight={'32px'} textAlign={'center'}>
                ${t('partners.price')}
              </Typography>
              <Typography fontSize={24} lineHeight={'32px'} fontWeight={500} textAlign={'center'}>
                $USD {Number(apiRamPrice).toString() === 'NaN' ? '--' : Number(apiRamPrice).toFixed(6)}
              </Typography>
            </Stack>
            <Stack>
              <Typography fontSize={16} lineHeight={'32px'} textAlign={'center'}>
                APY
              </Typography>
              <Typography fontSize={24} lineHeight={'32px'} fontWeight={500} textAlign={'center'}>
                {apy ? CurrencyAmount.ether(JSBI.multiply(JSBI.BigInt(apy), JSBI.BigInt('100'))).toFixed(0) : '--'} %
              </Typography>
            </Stack>
          </Stack>
          <Stack
            display={'none'}
            mb={5}
            direction={'row'}
            width={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Image width={20} height={20} src={lockIcon}></Image>
            <Typography fontSize={16} lineHeight={'32px'}>
              {t('partners.tvl')}
            </Typography>
          </Stack>
          <Stack display={'none'} direction={'row'} justifyContent={'space-between'}>
            <Typography fontWeight={500} fontSize={24} lineHeight={'32px'}>
              $RAM
              {totalStaked ? ` ${CurrencyAmount.ether(totalStaked).toSignificant(9, { groupSeparator: ',' })}` : '--'}
            </Typography>
            <Typography fontWeight={500} fontSize={24} lineHeight={'32px'}>
              $USD{amounts2Usdt}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          height={264}
          justifyContent={'center'}
          alignItems={'left'}
          padding={isMobile ? '40px 20px' : '27px 32px'}
          width={isMobile ? '90%' : 767}
          border={'2px solid #000'}
          margin={'auto 5px'}
        >
          <Stack spacing={30} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography fontWeight={700} fontSize={24} lineHeight={'32px'}>
              {t('yInfo')}
            </Typography>

            <Button
              disabled={refAddress !== ZERO_ADDRESS}
              onClick={() => setIsBindOpen(true)}
              style={{ width: 120, height: 40, borderRadius: '35.5px', color: '#1A0958', fontSize: 12 }}
            >
              {refAddress && refAddress !== ZERO_ADDRESS ? shortenAddress(refAddress) : t('inviteLink')}
            </Button>
          </Stack>
          <Stack
            sx={{
              marginTop: 30,
              padding: isMobile ? '30px 20px' : '20px 0',
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderRadius: '12px',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <ShowOnMobile sx={{ width: '100%' }}>
              <Stack width={'100%'} mb={20} alignItems={'center'}>
                <Image width={120} src={Coins} />
              </Stack>
            </ShowOnMobile>
            <Stack spacing={5} height={80}>
              <Typography fontSize={isMobile ? 12 : 16} lineHeight={'32px'}>
                {t('contribution')}
              </Typography>
              <Typography textAlign={'center'} fontSize={isMobile ? 16 : 36} fontWeight={600} lineHeight={'32px'}>
                {contribution ? contribution.toString() : '--'}
              </Typography>
            </Stack>

            <Stack spacing={5} height={80}>
              <Typography fontSize={isMobile ? 12 : 16} lineHeight={'32px'} textAlign={'center'}>
                {t('stakedAmount')}
              </Typography>
              <Typography textAlign={'center'} fontSize={isMobile ? 16 : 36} fontWeight={600} lineHeight={'32px'}>
                {stakedAmount ? CurrencyAmount.ether(stakedAmount).toSignificant(6, { groupSeparator: ',' }) : '--'}
              </Typography>
              <Typography textAlign={'center'} fontSize={isMobile ? 12 : 16} lineHeight={'32px'}>
                $Ram
              </Typography>
            </Stack>

            <Stack spacing={5} height={80}>
              <Typography fontSize={isMobile ? 12 : 16} lineHeight={'32px'} textAlign={'center'}>
                {t('claimAbleAmount')}
              </Typography>
              <Typography textAlign={'center'} fontSize={isMobile ? 16 : 36} fontWeight={600} lineHeight={'32px'}>
                {stakedReward && contributionReward
                  ? CurrencyAmount.ether(
                      JSBI.ADD(JSBI.BigInt(stakedReward), JSBI.BigInt(contributionReward))
                    ).toSignificant(6, { groupSeparator: ',' })
                  : '--'}
              </Typography>
              <Typography textAlign={'center'} fontSize={isMobile ? 12 : 16} lineHeight={'32px'}>
                $Ram
              </Typography>
            </Stack>

            <Stack spacing={5} height={80}>
              <Typography fontSize={isMobile ? 12 : 16} lineHeight={'32px'} textAlign={'center'}>
                {t('AnnualCompound')}
              </Typography>

              <Typography textAlign={'center'} fontSize={isMobile ? 16 : 36} fontWeight={600} lineHeight={'32px'}>
                {apy ? CurrencyAmount.ether(JSBI.multiply(JSBI.BigInt(apy), JSBI.BigInt('100'))).toFixed(0) : '--'} %
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        sx={{
          position: 'relative',
          zIndex: 10
        }}
        width={'100%'}
        marginTop={40}
        spacing={20}
        direction={'row'}
        justifyContent={'space-between'}
      >
        {/* left */}
        <Stack
          justifyContent={'flex-start'}
          alignItems={'center'}
          padding={0}
          width={isMobile ? '90%' : 768}
          height={400}
          border={'2px solid #000'}
        >
          <Stack direction={'row'} width={'100%'}>
            <Button
              onClick={() => setIsDeposit(true)}
              style={{
                borderRadius: 0,
                backgroundColor: isDeposit ? '#fff' : '#D9D9D9',
                borderWidth: '0px 2px 2px 0px',
                borderStyle: 'solid',
                borderColor: isDeposit ? 'rgba(0,0,0,0)' : '#000',
                '&:hover': { backgroundColor: isDeposit ? '#fff' : '#D9D9D9', borderColor: 'rgba(0,0,0,0)' }
              }}
            >
              {t('save')}
            </Button>
            <Button
              onClick={() => setIsDeposit(false)}
              style={{
                borderRadius: 0,
                backgroundColor: !isDeposit ? '#fff' : '#D9D9D9',
                borderWidth: '0px 0px 2px 2px',
                borderStyle: 'solid',
                borderColor: !isDeposit ? 'rgba(0,0,0,0)' : '#000',
                '&:hover': { backgroundColor: !isDeposit ? '#fff' : '#D9D9D9', borderColor: 'rgba(0,0,0,0)' }
              }}
            >
              {t('withdraw')}
            </Button>
          </Stack>
          <Stack
            sx={{
              marginRight: 'auto',
              padding: '0 44px',
              height: 32
            }}
            mt={35}
            mb={13}
          >
            <Typography fontSize={16} lineHeight={'32px'} textAlign={'left'}>
              {t('enterAmount')}
            </Typography>
          </Stack>
          <Stack height={88} padding={'0 44px'} direction={isMobile ? 'column' : 'row'} width={'100%'} spacing={20}>
            <NumericalInput
              style={{ backgroundColor: '#F0F0F0' }}
              balance={balanceAmount?.toExact()}
              placeholder={''}
              value={typed}
              height={47}
              onChange={e => {
                setTyped(e.target.value)
              }}
              onMax={() => {
                if (balanceAmount) {
                  setTyped(balanceAmount?.toExact() ?? '')
                }
              }}
            />
            {!isDeposit && (
              <ActionButton
                width={isMobile ? '100%' : '179px'}
                height={'47px'}
                pendingText={t('approving')}
                pending={approvalState === ApprovalState.PENDING}
                disableAction={
                  !balanceAmount ||
                  !inputAmount ||
                  mintDisable ||
                  !enoughAsset ||
                  !refAddress ||
                  refAddress === ZERO_ADDRESS
                }
                actionText={
                  !inputAmount
                    ? t('enterAmount')
                    : mintDisable
                    ? t('max')
                    : !enoughAsset
                    ? t('insufficient')
                    : approvalState === ApprovalState.NOT_APPROVED
                    ? `${t('allow')}`
                    : isDeposit
                    ? `${t('save')} RAM`
                    : `${t('witdhraw')} RAM`
                }
                onAction={
                  !isDeposit
                    ? unstakeCallback
                    : approvalState === ApprovalState.NOT_APPROVED
                    ? approveCallback
                    : stakeCallback
                }
              />
            )}
          </Stack>

          {/* deposit btn */}
          {isDeposit && (
            <Stack direction={isMobile ? 'column' : 'row'} width={'100%'} padding={'0 44px'} spacing={20}>
              <ActionButton
                width={'100%'}
                height={'47px'}
                pendingText={t('approving')}
                pending={approvalState === ApprovalState.PENDING}
                disableAction={
                  !balanceAmount ||
                  !inputAmount ||
                  mintDisable ||
                  !enoughAsset ||
                  !refAddress ||
                  refAddress === ZERO_ADDRESS
                }
                actionText={
                  !inputAmount
                    ? t('enterAmount')
                    : mintDisable
                    ? t('max')
                    : !enoughAsset
                    ? t('insufficient')
                    : approvalState === ApprovalState.NOT_APPROVED
                    ? `${t('allow')}`
                    : isDeposit
                    ? `${t('save')} RAM`
                    : `${t('witdhraw')} RAM`
                }
                onAction={
                  !isDeposit
                    ? unstakeCallback
                    : approvalState === ApprovalState.NOT_APPROVED
                    ? approveCallback
                    : stakeCallback
                }
              />
            </Stack>
          )}

          {/* widthdraw btn */}
          {!isDeposit && (
            <Stack direction={isMobile ? 'column' : 'column'} width={'100%'} padding={'0 44px'}>
              <Button
                disabled={
                  !stakedReward ||
                  !contributionReward ||
                  !JSBI.greaterThan(
                    JSBI.ADD(JSBI.BigInt(stakedReward), JSBI.BigInt(contributionReward)),
                    JSBI.BigInt('0')
                  )
                }
                height={'47px'}
                onClick={claimCallback}
              >
                {t('claimReward')}
              </Button>
              <Button
                disabled={
                  !stakedReward ||
                  !contributionReward ||
                  !stakedAmount ||
                  !JSBI.greaterThan(JSBI.BigInt(stakedAmount), JSBI.BigInt('0')) ||
                  !JSBI.greaterThan(
                    JSBI.ADD(JSBI.BigInt(stakedReward), JSBI.BigInt(contributionReward)),
                    JSBI.BigInt('0')
                  )
                }
                style={{
                  marginTop: 13,
                  color: '#000',
                  background: 'none',
                  lineHeight: '20px',
                  fontSize: 10,
                  textDecoration: 'underline'
                }}
                height={'20px'}
                onClick={exitCallback}
              >
                {t('exit')}
              </Button>
            </Stack>
          )}
          {/* refAddress && refAddress !== ZERO_ADDRESS */}
          {
            <Stack
              padding={'0 44px'}
              mt={20}
              width={'100%'}
              direction={'row'}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <Typography mr={10} color={'#000'} fontSize={14} lineHeight={'14px'}>
                {t('myInviteLink')}
              </Typography>
              <Stack
                sx={{
                  width: 304,
                  height: 36,
                  background: 'rgba(254, 241, 63, 0.33)',
                  border: '1px solid #FEF13F',
                  borderRadius: '10px'
                }}
                spacing={10}
                direction={'row'}
                alignItems={'center'}
                justifyContent={'flex-start'}
                padding={'0 15px'}
              >
                {account ? (
                  <Typography color={'#857800'} fontSize={14} lineHeight={'32px'} fontWeight={500}>
                    {isMobile
                      ? shortenText(`https://${window.location.host}/#/vault/ ${account}`)
                      : shortenText(`https://${window.location.host}/#/vault/ ${account}`, 14)}
                  </Typography>
                ) : (
                  <Typography color={'#857800'} fontSize={14} lineHeight={'32px'} fontWeight={500}>
                    --
                  </Typography>
                )}
                {account && <Copy toCopy={`https://${window.location.host}/#/vault/${account}`} />}
              </Stack>
            </Stack>
          }
        </Stack>

        {/* right */}
        <Stack
          width={448}
          height={400}
          sx={{
            background: '#FFFFFF',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
            padding: '0 19px'
          }}
        >
          <Stack spacing={6}>
            <Typography mt={35} mb={26} fontSize={12} lineHeight={'20px'} textAlign={'center'}>
              !! NOTICE !!
            </Typography>
            {/* <Typography fontSize={isMobile ? 16 : 24}>{t('stakingTip')}</Typography> */}
            <Typography fontSize={isMobile ? 12 : 14} lineHeight={'24px'}>
              {t('tip1')}
            </Typography>
            <Typography fontSize={isMobile ? 12 : 14}>{t('tip2')}</Typography>
            <Typography fontSize={isMobile ? 12 : 14}>{t('tip3')}</Typography>
            <Typography mt={'20px!important'} fontWeight={700} fontSize={isMobile ? 12 : 14}>
              {t('attention')}
            </Typography>
            <Typography lineHeight={'24px'} fontSize={isMobile ? 12 : 14}>
              {t('tip4')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          bottom: 0,
          zIndex: 1
        }}
      >
        <Image width={'100%'} src={valutBgText}></Image>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          width: 259,
          bottom: 0,
          left: 0,
          zIndex: 2
        }}
      >
        <Image width={'100%'} src={valutBgLine}></Image>
      </Box>
    </IDOFrame>
  )
}
