import { useStakingContract } from './useContract'
import { useSingleCallResult } from '../state/multicall/hooks'
import { useCallback, useMemo } from 'react'
import { CurrencyAmount } from '../constants/token'
import JSBI from 'jsbi'
import { calculateGasMargin, isAddress } from '../utils'
import { TransactionResponse } from '@ethersproject/providers'
import { useActiveWeb3React } from './index'
import { useTransactionAdder } from '../state/transactions/hooks'
import { FIRST_ADDRESS, ZERO_ADDRESS } from '../constants'
import { useI18n } from 'react-simple-i18n'

export function useStaking() {
  const { t } = useI18n()
  const { account } = useActiveWeb3React()
  const contract = useStakingContract()
  const addTransaction = useTransactionAdder()

  const userInfo = useSingleCallResult(contract, 'userInfoOf', [account ?? undefined])?.result
  const rewards = useSingleCallResult(contract, 'earned', [account ?? undefined])?.result
  const totalStaked = useSingleCallResult(contract, 'totalStakedAmount')?.result
  const apy = useSingleCallResult(contract, 'getAPY')?.result

  const stake = useCallback(
    async (amount: CurrencyAmount) => {
      if (!account) throw new Error('none account')
      if (!contract) throw new Error('none contract')
      if (amount.equalTo(JSBI.BigInt('0'))) throw new Error('amount is un support')
      const args = [amount.raw.toString()]
      const method = 'stake'
      console.log('🚀 ~ file: stake.ts ~ line 18 ~ args', args, method)
      return contract.estimateGas[method](...args, { from: account }).then(estimatedGasLimit => {
        return contract[method](...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
          // gasLimit: '3500000',
          from: account
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: t('save')
          })
          return response.hash
        })
      })
    },
    [account, addTransaction, contract, t]
  )

  const unstake = useCallback(
    async (amount: CurrencyAmount) => {
      if (!account) throw new Error('none account')
      if (!contract) throw new Error('none contract')
      if (amount.equalTo(JSBI.BigInt('0'))) throw new Error('amount is un support')
      const args = [amount.raw.toString()]
      const method = 'unstake'
      console.log('🚀 ~ file: stake.ts ~ line 18 ~ args', args, method)
      return contract.estimateGas[method](...args, { from: account }).then(estimatedGasLimit => {
        return contract[method](...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
          // gasLimit: '3500000',
          from: account
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: t('withdraw')
          })
          return response.hash
        })
      })
    },
    [account, addTransaction, contract, t]
  )

  const claim = useCallback(async () => {
    if (!account) throw new Error('none account')
    if (!contract) throw new Error('none contract')
    const method = 'claimReward'
    console.log('🚀 ~ file: stake.ts ~ line 18 ~ args', method)
    return contract.estimateGas[method]({ from: account }).then(estimatedGasLimit => {
      return contract[method]({
        gasLimit: calculateGasMargin(estimatedGasLimit),
        // gasLimit: '3500000',
        from: account
      }).then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: t('claimReward')
        })
        return response.hash
      })
    })
  }, [account, addTransaction, contract, t])

  const exit = useCallback(async () => {
    if (!account) throw new Error('none account')
    if (!contract) throw new Error('none contract')
    const method = 'exit'
    console.log('🚀 ~ file: stake.ts ~ line 18 ~ args', method)
    return contract.estimateGas[method]({ from: account }).then(estimatedGasLimit => {
      return contract[method]({
        gasLimit: calculateGasMargin(estimatedGasLimit),
        // gasLimit: '3500000',
        from: account
      }).then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: t('exit')
        })
        return response.hash
      })
    })
  }, [account, addTransaction, contract, t])

  const register = useCallback(
    async (destinationAddress: string) => {
      if (!account) throw new Error('none account')
      if (!contract) throw new Error('none contract')
      const method = 'register'
      const args = [destinationAddress]
      console.log('🚀 ~ file: stake.ts ~ line 18 ~ args', method)
      return contract.estimateGas[method](...args, { from: account }).then(estimatedGasLimit => {
        return contract[method](...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
          // gasLimit: '3500000',
          from: account
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: t('bind'),
            claim: { recipient: `${account}_bind` }
          })
          return response.hash
        })
      })
    },
    [account, addTransaction, contract, t]
  )
  return {
    stakedAmount: userInfo?.stakedAmount,
    stakedReward: rewards?.stakedReward,
    contribution: userInfo?.contribution,
    contributionReward: rewards?.contributionReward,
    refAddress: userInfo?.refAddress,
    totalStaked: totalStaked?.[0],
    stake,
    unstake,
    claim,
    register,
    exit,
    apy: apy?.[0]
  }
}

export function useAvailableAddress(address: string | undefined) {
  const contract = useStakingContract()
  const addr = isAddress(address)
  const userInfo = useSingleCallResult(contract, 'userInfoOf', [addr ? addr : undefined])
  return useMemo(() => {
    if (address?.toLowerCase() === FIRST_ADDRESS.toLowerCase()) return { loading: false, able: true }
    if (!addr) return { loading: false, able: false }
    if (!userInfo?.result) return { loading: false, able: true }
    return { loading: userInfo.loading, able: userInfo.result.refAddress.toLowerCase() !== ZERO_ADDRESS.toLowerCase() }
  }, [addr, address, userInfo.loading, userInfo.result])
}
