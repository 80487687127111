import { useRamboxNFTStakingContract } from './useContract'
import { useSingleCallResult } from '../state/multicall/hooks'
import { useCallback } from 'react'
import { calculateGasMargin } from '../utils'
import { TransactionResponse } from '@ethersproject/providers'
import { useActiveWeb3React } from './index'
import { useTransactionAdder } from '../state/transactions/hooks'
import { BigNumber } from '@ethersproject/bignumber'
import { useI18n } from 'react-simple-i18n'

export function useRomboxNFTStaking() {
  const { account } = useActiveWeb3React()
  const contract = useRamboxNFTStakingContract()
  const addTransaction = useTransactionAdder()
  const { t } = useI18n()
  const stakingNum = useSingleCallResult(contract, 'stakingNum', [account ?? undefined])?.result

  const stake = useCallback(async () => {
    if (!account) throw new Error('none account')
    if (!contract) throw new Error('none contract')
    const method = 'stake'
    console.log('🚀 ~ file: stake.ts ~ line 18 ~ args', method)
    return contract.estimateGas[method]({ from: account }).then(estimatedGasLimit => {
      return contract[method]({
        gasLimit: calculateGasMargin(estimatedGasLimit),
        // gasLimit: '3500000',
        from: account
      }).then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: t('save')
        })
        return response.hash
      })
    })
  }, [account, addTransaction, contract, t])

  const claim = useCallback(
    async (start: number, end: number, reward: string, signature: string) => {
      if (!account) throw new Error('none account')
      if (!contract) throw new Error('none contract')
      const method = 'claim'
      const args = [account, start, end, BigNumber.from(reward).toString(), signature]
      console.log('🚀 ~ file: stake.ts ~ line 18 ~ args', method, args)

      return contract[method](...args, {
        from: account
      }).then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: t('save')
        })
        return response.hash
      })
      // })
    },
    [account, addTransaction, contract, t]
  )

  const withdraw = useCallback(async () => {
    if (!account) throw new Error('none account')
    if (!contract) throw new Error('none contract')
    const method = 'withdraw'
    console.log('🚀 ~ file: stake.ts ~ line 18 ~ args', method)
    return contract.estimateGas[method]({ from: account }).then(estimatedGasLimit => {
      return contract[method]({
        gasLimit: calculateGasMargin(estimatedGasLimit),
        // gasLimit: '3500000',
        from: account
      }).then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: t('save')
        })
        return response.hash
      })
    })
  }, [account, addTransaction, contract, t])
  return {
    stakingNum: stakingNum ? stakingNum.toString() : 0,
    stake,
    withdraw,
    claim
  }
}
