import axios, { AxiosResponse, AxiosPromise, AxiosRequestConfig } from 'axios'

const axiosInstance = axios.create({
  baseURL: 'https://staking.ramx.tech',
  timeout: 10000,
  headers: {
    'content-type': 'application/json',
    accept: 'application/json'
  }
})

type Response<T = any> = {
  massage?: string
  code: number
  data: T
}

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = localStorage.getItem('jwt')
    if (token && config.headers && config.url != 'https://service.rambox.io/mexc-api/api/v3/avgPrice/?symbol=RAMUSDT') {
      config.headers.jwt = `${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export const Axios = {
  get<T = any>(url: string, params: { [key: string]: any } = {}): AxiosPromise<ResponseType<T> | any> {
    return axiosInstance.get(url, { params })
  },
  post<T = any>(url: string, data: { [key: string]: any }, params = {}): AxiosPromise<ResponseType<T> | Response> {
    return axiosInstance.post(url, data, { params })
  }
}

export type AxiosResponseType<T = any, D = any> = AxiosResponse<T, D>

export interface ResponseType<T = any> {
  msg: string
  code: number
  data: T
}
