import { Stack, styled, Typography, Box } from '@mui/material'
import Web3 from 'web3'
// import { HideOnMobile, ShowOnMobile } from 'theme/index'
import ActionButton from '../../components/Button/ActionButton'
import { useActiveWeb3React } from '../../hooks'
import { useCallback, useMemo, useEffect, useState } from 'react'
import useModal from '../../hooks/useModal'
import TransactionPendingModal from 'components/Modal/TransactionModals/TransactionPendingModal'
import TransactionSubmittedModal from '../../components/Modal/TransactionModals/TransactiontionSubmittedModal'
import MessageBox from '../../components/Modal/TransactionModals/MessageBox'
import { isMobile } from 'react-device-detect'
import topStoryBackimg from '../../assets/images/top_story_backimg.png'
import Image from '../../components/Image'
import valutBgText from '../../assets/images/vault-bg-text.png'
import valutBgLine from '../../assets/images/vault-bg-line.png'
import { useI18n } from 'react-simple-i18n'
import NFTImg from '../../assets/images/nft-img.png'
import Button from 'components/Button/Button'
import { useRomboxNFTStaking } from '../../hooks/useRomboxNFTStaking'
import { useERC721ApproveAllCallback } from '../../hooks/useERC721ApproveAllCallback'
import { RAMNFT, ROMBOX_NFT_STAKING_ADDRESS } from '../../constants'
import { useWalletModalToggle } from 'state/application/hooks'
import { Axios } from 'utils/axios'
import { handlePrice } from 'utils'
import moment from 'moment'
import useCountdown from 'hooks/useCountdown'
import { BigNumber } from '@ethersproject/bignumber'

// import { BigNumber } from 'ethers'
// import JSBI from 'jsbi'
const IDOFrame = styled('div')`
  height: auto;
  background-image: url(${topStoryBackimg});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  padding-top: 149px;
  min-height: 100vh;
`
const Title = styled(Typography)`
  font-size: 64px;
  font-family: 'Kanit';
  font-style: italic;
  color: #000;
  font-weight: 700;
  margin-right: auto;
  margin-bottom: 28px;
`
export default function Stake() {
  const { t } = useI18n()
  const toggleWalletModal = useWalletModalToggle()
  console.log(t)
  const { account, chainId } = useActiveWeb3React()
  const { showModal, hideModal } = useModal()
  const { stakingNum, stake, withdraw, claim } = useRomboxNFTStaking()
  const [allowClaim, setAllowClaim] = useState(false)
  const [reward, setReward] = useState('-')
  const [totalRevenue, setTotalRevenue] = useState('--')
  const [claimed, setClaimed] = useState('--')
  const [approvalState, approveCallback] = useERC721ApproveAllCallback(
    RAMNFT[chainId ?? 56],
    ROMBOX_NFT_STAKING_ADDRESS[chainId ?? 56]
  )

  console.log(approvalState, 'approvalState')

  useEffect(() => {
    if (!account) return
    const getInfo = () => {
      return Axios.get(`/info?address=${account}`)
        .then(res => {
          console.log(res, 'infoinfo')

          if (res.data.data.address) {
            if (res.data.code !== 200 || res.data.data.address != account) {
              clearInterval(intervalInfo)
              return
            }
            const { reward, taken_reward } = res.data.data
            const rewardNum = Web3.utils.fromWei(
              String(BigNumber.from(reward.number || reward.number === 0 ? reward.number : reward))
            )
            const takenRewardNum = Web3.utils.fromWei(
              String(
                BigNumber.from(taken_reward.number || taken_reward.number === 0 ? taken_reward.number : taken_reward)
              )
            )
            setTotalRevenue(rewardNum)
            setReward(String(Number(rewardNum) - Number(takenRewardNum)))
            setClaimed(takenRewardNum)
            if (Number(rewardNum) - Number(takenRewardNum) > 0) {
              setAllowClaim(true)
            }
          }
        })
        .catch(err => {
          console.log(err, 'err')
          clearInterval(intervalInfo)
        })
    }
    const intervalInfo = setInterval(() => {
      getInfo()
    }, 1000)

    return () => {
      clearInterval(intervalInfo)
    }
  }, [account])

  const deadline = useMemo(
    () =>
      moment('2023-7-14 18:00:00')
        // 设置时间
        .format('YYYY-MM-DD HH:mm:ss'),
    []
  )
  const { hour, minute, second } = useCountdown({
    deadline
  })
  const claimCallback = useCallback(() => {
    if (!account) return
    Axios.get(`/claim?address=${account}`).then(res => {
      const { start, end, reward, signature } = res.data.data
      claim(start, end, reward, signature)
        .then(() => {
          hideModal()
          showModal(<TransactionSubmittedModal />)
        })
        .catch((err: any) => {
          hideModal()
          showModal(
            <MessageBox type="error">{err.error && err.error.message ? err.error.message : err?.message}</MessageBox>
          )
          console.error(err)
        })
    })
  }, [account, showModal, claim, hideModal])
  const stakeCallback = useCallback(async () => {
    if (!account) return
    showModal(<TransactionPendingModal />)
    stake()
      .then(() => {
        hideModal()
        showModal(<TransactionSubmittedModal />)
      })
      .catch((err: any) => {
        hideModal()
        showModal(
          <MessageBox type="error">{err.error && err.error.message ? err.error.message : err?.message}</MessageBox>
        )
        console.error(err)
      })
  }, [account, showModal, stake, hideModal])

  const withdrawCallback = useCallback(async () => {
    if (!account) return
    showModal(<TransactionPendingModal />)
    withdraw()
      .then(() => {
        hideModal()
        showModal(<TransactionSubmittedModal />)
      })
      .catch((err: any) => {
        hideModal()
        showModal(
          <MessageBox type="error">{err.error && err.error.message ? err.error.message : err?.message}</MessageBox>
        )
        console.error(err)
      })
  }, [account, showModal, withdraw, hideModal])
  const NFTButtom = useMemo(() => {
    if (!(hour === 0 && minute === 0 && second === 0)) {
      return (
        <Box
          sx={{
            textAlign: 'center',
            border: '1px solid #FEF13F',
            height: 45,
            fontSize: 22,
            lineHeight: '45px',
            borderRadius: '10px',
            width: '100%',
            margin: '0 auto 0!important'
          }}
        >
          {hour < 10 ? '0' + hour : hour}:{minute < 10 ? '0' + minute : minute}:{second < 10 ? '0' + second : second}
        </Box>
      )
    }
    if (!account) {
      return (
        <>
          <ActionButton
            hasIcon={true}
            height={'48px'}
            pendingText={'Loading'}
            onAction={toggleWalletModal}
            actionText={'Connect Wallet'}
          />
        </>
      )
    }
    if (stakingNum === '1') {
      return (
        <>
          <ActionButton
            hasIcon={true}
            height={'48px'}
            pendingText={'Loading'}
            onAction={withdrawCallback}
            actionText={'WITHDRAW'}
          />
        </>
      )
    }
    if (approvalState !== 3) {
      if (approvalState === 2) {
        return (
          <>
            <ActionButton
              hasIcon={true}
              height={'48px'}
              pendingText={'Loading'}
              disableAction={true}
              onAction={undefined}
              actionText={'Approving'}
            />
          </>
        )
      } else if (approvalState === 1) {
        return (
          <>
            <ActionButton
              hasIcon={true}
              height={'48px'}
              pendingText={'Loading'}
              onAction={approveCallback}
              actionText={'Approval'}
            />
          </>
        )
      } else {
        return (
          <>
            <ActionButton
              hasIcon={true}
              height={'48px'}
              pendingText={'Loading'}
              disableAction={true}
              onAction={undefined}
              actionText={'Loading...'}
            />
          </>
        )
      }
    }

    return (
      <>
        <ActionButton
          hasIcon={true}
          height={'48px'}
          // pending={loading || binding}
          pendingText={'Loading'}
          disableAction={false}
          onAction={stakeCallback}
          actionText={t('STAKE')}
        />
      </>
    )
  }, [
    account,
    approvalState,
    stakeCallback,
    approveCallback,
    toggleWalletModal,
    stakingNum,
    withdrawCallback,
    hour,
    minute,
    second,
    t
  ])
  function NFTButtomFun() {
    return NFTButtom
  }
  const ShadowTxt = ({ txt }: { txt: string }) => {
    return (
      <Typography
        component="span"
        sx={{
          fontSize: 24,
          lineHeight: '32px',
          textAlign: 'center',
          fontWeight: 700
        }}
      >
        {txt}
        {/* <Box
          sx={{
            background: '#FEF13F',
            width: '100%',
            height: '100%'
          }}
        ></Box> */}
      </Typography>
    )
  }
  return (
    <IDOFrame sx={{ width: isMobile ? '100%' : '1248px' }}>
      <Title>{t('STAKE')}</Title>
      <Stack
        sx={{
          position: 'relative',
          zIndex: 10
        }}
        direction={'row'}
        justifyContent={'space-between'}
        width={'100%'}
        spacing={20}
      >
        <Stack
          justifyContent={'space-around'}
          padding={'30px 50px'}
          height={528}
          width={'50%'}
          border={'2px solid #000'}
        >
          <Stack
            sx={{
              height: 117
            }}
            spacing={15}
          >
            <Typography textAlign={'center'} fontSize={24} lineHeight={'32px'}>
              {t('totalRevenue')}
            </Typography>
            <Stack spacing={20} direction={'row'} justifyContent={'center'} alignItems={'center'}>
              <Typography fontStyle={'italic'} fontSize={64} fontWeight={700} lineHeight={'70px'}>
                {handlePrice(totalRevenue)}
              </Typography>
              <ShadowTxt txt={'$RAM'} />
            </Stack>
          </Stack>
          <Stack
            sx={{
              height: 117
            }}
            spacing={15}
          >
            <Typography textAlign={'center'} fontSize={24} lineHeight={'32px'}>
              {t('claimed')}
            </Typography>
            <Stack spacing={20} direction={'row'} justifyContent={'center'} alignItems={'center'}>
              <Typography fontStyle={'italic'} fontSize={64} fontWeight={700} lineHeight={'70px'}>
                {handlePrice(claimed)}
              </Typography>
              <ShadowTxt txt={'$RAM'} />
            </Stack>
          </Stack>
          <Stack
            sx={{
              height: 117,
              display: 'none'
            }}
            spacing={15}
          >
            <Typography textAlign={'center'} fontSize={24} lineHeight={'32px'}>
              APY
            </Typography>
            <Stack spacing={20} direction={'row'} justifyContent={'center'} alignItems={'center'}>
              <Typography fontStyle={'italic'} fontSize={64} fontWeight={700} lineHeight={'70px'}>
                -%
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack height={528} padding={'21px 18px'} width={'50%'} border={'2px solid #000'} margin={'auto 5px'}>
          <ShadowTxt txt={'Contributor NFT'} />
          <Box
            sx={{
              marginTop: 31,
              width: 425,
              height: 298,
              margin: '31px auto'
            }}
          >
            <Image width={'100%'} height={'100%'} src={NFTImg}></Image>
          </Box>

          <NFTButtomFun />
          {/* <ActionButton
            hasIcon={true}
            height={'48px'}
            // pending={loading || binding}
            pendingText={'Loading'}
            // disableAction={!able || refAddress !== ZERO_ADDRESS}
            onAction={stakeCallback}
            actionText={'STAKE'}
          />
          <ActionButton
            hasIcon={true}
            height={'48px'}
            // pending={loading || binding}
            pendingText={'Loading'}
            // disableAction={!able || refAddress !== ZERO_ADDRESS}
            onAction={approveCallback}
            actionText={'approve'}
          /> */}
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            sx={{
              height: 32,
              width: '100%',
              marginTop: 25
            }}
          >
            <Box
              sx={{
                fontSize: 24,
                fontWeight: 700,
                lineHeight: '32px'
              }}
            >
              {t('reward')}
            </Box>
            <Stack spacing={15} direction={'row'} sx={{ height: 32 }}>
              <Box
                sx={{
                  fontSize: 36,
                  lineHeight: '32px',
                  fontWeight: 700
                }}
              >
                {handlePrice(reward)}
              </Box>
              <ShadowTxt txt={'$RAM'} />
              <Button
                disabled={!allowClaim}
                onClick={() => claimCallback()}
                style={{
                  height: '32px',
                  padding: '0 20px'
                }}
              >
                {t('claim')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          bottom: 0,
          zIndex: 1
        }}
      >
        <Image width={'100%'} src={valutBgText}></Image>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          width: 259,
          bottom: 0,
          left: 0,
          zIndex: 2
        }}
      >
        <Image width={'100%'} src={valutBgLine}></Image>
      </Box>
    </IDOFrame>
  )
}
