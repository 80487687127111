import Button from './Button'
import OutlineButton from './OutlineButton'
import Spinner from 'components/Spinner'
import { Typography } from '@mui/material'
// import Image from '../Image'
import LockIcon from '@mui/icons-material/Lock'

export default function ActionButton({
  error,
  pending,
  success,
  onAction,
  actionText,
  pendingText,
  height,
  width,
  disableAction,
  successText,
  hasIcon
}: {
  error?: string | undefined
  pending?: boolean
  success?: boolean
  onAction: (() => void) | undefined
  actionText: string
  pendingText?: string
  successText?: string
  height?: string
  width?: string
  disableAction?: boolean
  hasIcon?: boolean
}) {
  return (
    <>
      {error || pending ? (
        <OutlineButton style={{ color: '#1A0958' }} primary disabled height={height} width={width}>
          {pending ? (
            <>
              <Spinner marginRight={16} />
              {pendingText || 'Waiting Confirmation'}
            </>
          ) : (
            error
          )}
        </OutlineButton>
      ) : success ? (
        <Button style={{ color: '#1A0958' }} disabled height={height} width={width}>
          <Typography variant="inherit">{successText ?? actionText}</Typography>
        </Button>
      ) : (
        <Button style={{ color: '#1A0958' }} height={height} width={width} onClick={onAction} disabled={disableAction}>
          {hasIcon && <LockIcon />}
          {actionText}
        </Button>
      )}
    </>
  )
}
