import { Box, Button, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material'
import { useI18n } from 'react-simple-i18n'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.css'
import 'swiper/modules/autoplay/autoplay.min.css'
import Image from '../../components/Image'
import topImage from '../../assets/images/top-img.png'
import bannerImage from '../../assets/images/banner-img.png'
import ramBoxText from '../../assets/images/rambox-text.png'
import videoModal from '../../assets/images/video-modal.png'
import topText from '../../assets/images/top-text.png'
import topTextCn from '../../assets/images/top-text-cn.png'
import storyLeft from '../../assets/images/story_left.png'
import productItemBg from '../../assets/images/product-item.png'
import productItemHoverBg from '../../assets/images/product-item-hover.png'
import productItem1 from '../../assets/images/product-item-1.png'
import productItem2 from '../../assets/images/product-item-2.png'
import productItem3 from '../../assets/images/product-item-3.png'
import productArr from '../../assets/images/product_arr.png'
import modalLast from '../../assets/images/modal_last.png'
import modalNext from '../../assets/images/modal_next.png'
import fluentArrow from '../../assets/images/fluent_arrow.png'
import fluentOrganization from '../../assets/images/fluent_organization.png'
import solarCpu from '../../assets/images/solar_cpu.png'
import bottomBg from '../../assets/images/bottom-bg.png'
import ramBg from '../../assets/images/ram-bg.png'
import ramICon1 from '../../assets/images/ram-icon-1.png'
import ramICon2 from '../../assets/images/ram-icon-2.png'
import ramICon3 from '../../assets/images/ram-icon-3.png'
import ramICon4 from '../../assets/images/ram-icon-4.png'
import NFTfiVaultImg from '../../assets/images/NFTfiVaultImg.png'
import networkIcon1 from '../../assets/images/network-icon-1.png'
import networkIcon2 from '../../assets/images/network-icon-2.png'
import networkIcon3 from '../../assets/images/network-icon-3.png'
import networkIcon4 from '../../assets/images/network-icon-4.png'
import arrowTop from '../../assets/images/arrow-top.png'
import carBon from '../../assets/images/carbon_crop.png'

// import game from '../../assets/images/game.png'
// import cards from '../../assets/images/cards.png'
// import web from '../../assets/images/web.png'
// import flower from '../../assets/images/flower.png'
import coin from '../../assets/images/coin.png'
// import { PROJECTS } from '../../constants'
import topBG from '../../assets/images/top-bg.png'
import modalTitleBg from '../../assets/images/modal-title-bg.png'
import { Autoplay } from 'swiper'
import { NavLink } from 'react-router-dom'
import { ExternalLink } from '../../theme/components'
import twitter from '../../assets/images/twitter_black.png'
import telegram from '../../assets/images/telegram_black.png'
import discord from '../../assets/images/discord_black.png'
import ProductModals from '../../components/Modal/productModals/index'
import { useState } from 'react'
import { CurrencyAmount } from '../../constants/token'
import { useStaking } from '../../hooks/useStaking'
import JSBI from 'jsbi'
import './index.css'
const TopFrame = styled('div')`
  width: 100%;
  min-width: 500px;
  min-height: 800px;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Title = styled(Typography)`
  font-size: 64px;
  font-family: 'Kanit';
  font-style: italic;
  color: #000;
  font-weight: 700;
`

const BuyButton = styled(Button)(({ theme }) => ({
  width: 266,
  height: 50,
  background: theme.palette.primary.main,
  color: theme.palette.text.primary,
  fontSize: 14,
  marginTop: 35,
  border: '1px solid ',
  borderColor: theme.palette.primary.main
}))

const GetButton = styled(Button)(({ theme }) => ({
  width: 224,
  height: 50,
  background: theme.palette.primary.main,
  color: theme.palette.text.primary,
  fontSize: 14,
  border: '1px solid ',
  borderColor: theme.palette.primary.main
}))

function SelectProductItem({ productItemIndex }: { productItemIndex: number }) {
  switch (productItemIndex) {
    case 0:
      return <NFTfiVault />
    case 1:
      return <ContributorNFT />
    case 2:
      return <RamboxNetwork />
    default:
      return <></>
  }
}

function NFTfiVault() {
  const { t } = useI18n()
  return (
    <>
      <Stack
        marginBottom={30}
        height={280}
        direction={'row'}
        spacing={70}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
      >
        <Image width={275} src={NFTfiVaultImg} />
        <Typography fontSize={16} lineHeight={'32px'} color={'#000'}>
          {t('productDesc1')}
        </Typography>
      </Stack>
      <Box
        sx={{
          margin: '0 auto',
          textAlign: 'center'
        }}
      >
        <NavLink style={{ textDecoration: 'none' }} to={'/vault/0x6B8ABb098113a566020c89745CcB7b41Edc4CFa2'}>
          <Button
            style={{
              background: '#fef13f',
              borderRadius: '10px',
              padding: '15px 20px',
              lineHeight: '20px',
              color: '#000',
              fontSize: 14
            }}
          >
            {t('gotovault')}
          </Button>{' '}
        </NavLink>
      </Box>
    </>
  )
}
function ContributorNFT() {
  const { t } = useI18n()
  return (
    <>
      <Stack height={242} direction={'row'} spacing={16} alignItems={'center'} justifyContent={'space-between'}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          width={291}
          height={242}
        >
          <Image width={45} height={45} src={solarCpu} />
          <Typography
            height={40}
            fontWeight={700}
            color={'#000'}
            fontSize={24}
            lineHeight={'32px'}
            textAlign={'center'}
            mt={14}
          >
            {t('prod2_1')}
          </Typography>
          <Typography lineHeight={'32px'} textAlign={'center'} mt={10} fontSize={16} fontWeight={400} color={'#000'}>
            {t('prod2_1_desc')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          width={291}
          height={242}
        >
          <Image width={45} height={45} src={fluentArrow} />
          <Typography
            height={40}
            fontWeight={700}
            color={'#000'}
            fontSize={24}
            lineHeight={'32px'}
            textAlign={'center'}
            mt={14}
          >
            {t('prod2_2')}
          </Typography>
          <Typography lineHeight={'32px'} textAlign={'center'} mt={10} fontSize={16} fontWeight={400} color={'#000'}>
            {t('prod2_2_desc')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          width={291}
          height={242}
        >
          <Image width={45} height={45} src={fluentOrganization} />
          <Typography
            height={40}
            fontWeight={700}
            color={'#000'}
            fontSize={24}
            lineHeight={'32px'}
            textAlign={'center'}
            mt={14}
          >
            {t('prod2_3')}
          </Typography>
          <Typography lineHeight={'32px'} textAlign={'center'} mt={10} fontSize={16} fontWeight={400} color={'#000'}>
            {t('prod2_3_desc')}{' '}
          </Typography>
        </Box>
      </Stack>
      <Stack
        marginBottom={30}
        height={242}
        direction={'row'}
        spacing={16}
        alignItems={'center'}
        justifyContent={'space-around'}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          width={291}
          height={242}
        >
          <Image width={45} height={45} src={arrowTop} />
          <Typography
            height={40}
            fontWeight={700}
            color={'#000'}
            fontSize={24}
            lineHeight={'32px'}
            textAlign={'center'}
            mt={14}
          >
            {t('prod2_4')}
          </Typography>
          <Typography lineHeight={'32px'} textAlign={'center'} mt={10} fontSize={16} fontWeight={400} color={'#000'}>
            {t('prod2_4_desc')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          width={291}
          height={242}
        >
          <Image width={45} height={45} src={carBon} />
          <Typography
            height={40}
            fontWeight={700}
            color={'#000'}
            fontSize={24}
            lineHeight={'32px'}
            textAlign={'center'}
            mt={14}
          >
            {t('prod2_5')}
          </Typography>
          <Typography lineHeight={'32px'} textAlign={'center'} mt={10} fontSize={16} fontWeight={400} color={'#000'}>
            {t('prod2_5_desc')}
          </Typography>
        </Box>
      </Stack>
      <Box
        sx={{
          margin: '0 auto',
          textAlign: 'center'
        }}
      >
        <NavLink style={{ textDecoration: 'none' }} to={'/mintNFT'}>
          <Button
            style={{
              background: '#fef13f',
              borderRadius: '10px',
              padding: '15px 20px',
              lineHeight: '20px',
              color: '#000',
              fontSize: 14
            }}
          >
            MINT NFT
          </Button>
        </NavLink>
      </Box>
    </>
  )
}
function RamboxNetwork() {
  const { t } = useI18n()
  return (
    <>
      <Stack marginBottom={30} direction={'column'} spacing={16} alignItems={'center'} justifyContent={'space-between'}>
        <Typography fontSize={16} lineHeight={'32px'} color={'#000'}>
          {t('productDesc3')}
        </Typography>
        <Stack width={'100%'} direction={'row'} spacing={28}>
          <Box
            sx={{
              width: 'calc(100%/4)',
              height: 126,
              border: '2px solid #000000',
              padding: '19px 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Image width={54} height={54} src={networkIcon1}></Image>
            <Typography fontWeight={700} fontSize={19} lineHeight={'26px'} color={'#000'} textAlign={'center'}>
              {t('prod3_1')}
            </Typography>
          </Box>
          <Box
            sx={{
              width: 'calc(100%/4)',
              height: 126,
              border: '2px solid #000000',
              padding: '19px 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Image width={54} height={54} src={networkIcon2}></Image>
            <Typography fontWeight={700} fontSize={19} lineHeight={'26px'} color={'#000'} textAlign={'center'}>
              {t('prod3_2')}
            </Typography>
          </Box>
          <Box
            sx={{
              width: 'calc(100%/4)',
              height: 126,
              border: '2px solid #000000',
              padding: '19px 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Image width={54} height={54} src={networkIcon3}></Image>
            <Typography fontWeight={700} fontSize={19} lineHeight={'26px'} color={'#000'} textAlign={'center'}>
              {t('prod3_3')}
            </Typography>
          </Box>
          <Box
            sx={{
              width: 'calc(100%/4)',
              height: 126,
              border: '2px solid #000000',
              padding: '19px 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Image width={54} height={54} src={networkIcon4}></Image>
            <Typography fontWeight={700} fontSize={19} lineHeight={'26px'} color={'#000'} textAlign={'center'}>
              {t('prod3_4')}
            </Typography>
          </Box>
        </Stack>
      </Stack>
      <Box
        sx={{
          margin: '0 auto',
          textAlign: 'center'
        }}
      >
        <Button
          style={{
            background: '#E3E3E3',
            borderRadius: '10px',
            padding: '15px 20px',
            lineHeight: '20px',
            color: '#000',
            fontSize: 14
          }}
        >
          {t('comingSoon')}
        </Button>
      </Box>
    </>
  )
}
function ProductModalsMain({ index, actionFun }: { index: number; actionFun: any }) {
  const { t } = useI18n()
  const titleList = [
    {
      title: t('NFTfiVault'),
      icon: ''
    },
    {
      title: t('ContributorNFT'),
      icon: ''
    },
    {
      title: t('RamboxNetwork'),
      icon: ''
    }
  ]
  const changePage = (action: string) => {
    if (action === 'last') {
      if (index === 0) {
        actionFun(2)
      } else {
        actionFun(index - 1)
      }
    }

    if (action === 'next') {
      if (index === 2) {
        actionFun(0)
      } else {
        actionFun(index + 1)
      }
    }
  }
  return (
    <Box>
      <Stack marginBottom={44} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Box
          sx={{
            cursor: 'pointer',
            marginLeft: 52
          }}
        >
          <Image width={42} height={42} src={modalLast} onClick={() => changePage('last')} />
        </Box>
        <Box
          sx={{
            width: 450,
            height: 72,
            background: `url(${modalTitleBg})`,
            lineHeight: '72px',
            textAlign: 'center'
          }}
        >
          {titleList[index].title}
        </Box>
        <Box
          sx={{
            cursor: 'pointer',
            marginRight: 52
          }}
        >
          <Image width={42} height={42} src={modalNext} onClick={() => changePage('next')} />
        </Box>
      </Stack>
      <SelectProductItem productItemIndex={index} />
    </Box>
  )
}
function ProductItem({ title, icon, index }: { title: string; icon: string; index: number }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEnter, setIsEnter] = useState(false)
  const [modalIndex, setModalIndex] = useState(0)

  function openModal(index: number) {
    setModalIndex(index)
    setIsModalOpen(true)
  }

  function setEnterItem(isEnter: boolean) {
    console.log(isEnter)

    setIsEnter(isEnter)
  }

  return (
    <>
      <ProductModals isOpen={isModalOpen} onDismiss={() => setIsModalOpen(false)}>
        <ProductModalsMain actionFun={setModalIndex} index={modalIndex} />
      </ProductModals>
      <Stack
        sx={{
          width: 1000,
          height: 98,
          color: '#000',
          cursor: 'pointer',
          paddingRight: 500,
          position: 'relative'
        }}
        onMouseEnter={() => setEnterItem(true)}
        onMouseLeave={() => setEnterItem(false)}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        onClick={() => openModal(index)}
      >
        <Box
          sx={{
            position: 'absolute',
            right: 95,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 11
          }}
        >
          <Image width={42} src={productArr} />
        </Box>
        <Stack
          sx={{
            position: 'relative',
            zIndex: 13
          }}
          direction={'row'}
          alignItems={'center'}
        >
          <Image width={48} height={48} src={icon} />
          <Typography ml={25} width={320} fontSize={32} lineHeight={'98px'}>
            {title}
          </Typography>
        </Stack>
        <Box
          sx={{
            position: 'absolute',
            zIndex: 10,
            width: 1000,
            height: 98,
            left: 0,
            top: 0,
            transitionDuration: '1s',
            '&:hover': {
              transform: 'scaleX(1.1)'
            }
          }}
        >
          <Image width={1000} height={98} src={productItemBg} />
        </Box>
        <Box
          className={`enterBg ${isEnter ? 'show' : 'hide'}`}
          sx={{
            position: 'absolute',
            zIndex: 9,
            width: 1065,
            height: 98,
            left: 0,
            top: 20,
            transitionDuration: '1s'
          }}
        >
          <Image width={1065} height={98} src={productItemHoverBg} />
        </Box>
      </Stack>
    </>
  )
}

// }

const banners = [topBG]

export default function Home() {
  const { apy } = useStaking()
  const { t, i18n } = useI18n()
  const logoList = [
    require('../../assets/images/logo-28.png'),
    require('../../assets/images/logo-1.png'),
    require('../../assets/images/logo-2.png'),
    require('../../assets/images/logo-3.png'),
    require('../../assets/images/logo-4.png'),
    require('../../assets/images/logo-5.png'),
    require('../../assets/images/logo-6.png'),
    require('../../assets/images/logo-7.png'),
    require('../../assets/images/logo-9.png'),
    require('../../assets/images/logo-10.png'),
    require('../../assets/images/logo-11.png'),
    require('../../assets/images/logo-12.png'),
    require('../../assets/images/logo-13.png'),
    require('../../assets/images/logo-14.png'),
    require('../../assets/images/logo-16.png'),
    require('../../assets/images/logo-17.png'),
    require('../../assets/images/logo-18.png'),
    require('../../assets/images/logo-19.png'),
    require('../../assets/images/logo-20.png'),
    require('../../assets/images/logo-21.png'),
    require('../../assets/images/logo-22.png'),
    require('../../assets/images/logo-23.png'),
    require('../../assets/images/logo-24.png'),
    require('../../assets/images/logo-25.png'),
    require('../../assets/images/logo-26.png'),
    require('../../assets/images/logo-27.png')
  ]

  const roadMapList = [
    [
      [t('roadMapV2.r1_1'), t('roadMapV2.r1_2'), t('roadMapV2.r1_3')],
      [t('roadMapV2.r2_1'), t('roadMapV2.r2_2')],
      [t('roadMapV2.r3_1'), t('roadMapV2.r3_2'), t('roadMapV2.r3_3')],
      [t('roadMapV2.r4_1'), t('roadMapV2.r4_2')]
    ],
    [
      [t('roadMapV2.r5_1'), t('roadMapV2.r5_2')],
      [
        t('roadMapV2.r6_1'),
        t('roadMapV2.r6_2'),
        t('roadMapV2.r6_3'),
        t('roadMapV2.r6_4'),
        t('roadMapV2.r6_5'),
        t('roadMapV2.r6_6'),
        t('roadMapV2.r6_7')
      ],
      [t('roadMapV2.r7_1'), t('roadMapV2.r7_2'), t('roadMapV2.r7_3')],
      [t('roadMapV2.r8_1'), t('roadMapV2.r8_2'), t('roadMapV2.r8_3')]
    ]
  ]
  const roadMapTitleList = [
    [t('roadMapV2.r1'), t('roadMapV2.r2'), t('roadMapV2.r3'), t('roadMapV2.r4')],
    [t('roadMapV2.r5'), t('roadMapV2.r6'), t('roadMapV2.r7'), t('roadMapV2.r8')],
    []
  ]

  return (
    <Box p={0} width={'100%'}>
      <TopFrame>
        <Stack>
          <Swiper modules={[Autoplay]} autoplay={true} style={{ width: '100vw' }}>
            {banners.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <Image style={{ width: '120vw', marginLeft: '-10vw' }} src={item} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </Stack>
        <Box sx={{ position: 'absolute', top: 149, right: 0, zIndex: 9 }}>
          <Image height={688} src={bannerImage} />
        </Box>
        <Box sx={{ position: 'absolute', top: 0, zIndex: 10, marginTop: 254 }}>
          <Box
            sx={{
              display: 'flex',
              minWidth: 1248,
              padding: '0',
              flexDirection: 'column',
              alignItems: 'flex-start',
              borderRadius: '20px',
              justifyContent: 'center',
              zIndex: 10
            }}
          >
            {i18n.getLang() === 'cn' ? <Image width={754} src={topTextCn} /> : <Image width={754} src={topText} />}

            <NavLink style={{ textDecoration: 'none' }} to={'/vault'}>
              <BuyButton>{t('entervault')}</BuyButton>
            </NavLink>
          </Box>

          <Stack height={35} width={1248} mt={175} direction={'row'}>
            <Typography fontWeight={400} color={'#000'} fontSize={10} lineHeight={'35px'}>
              {t('CSA')}
            </Typography>
            <Typography fontWeight={400} color={'#000'} fontSize={16} lineHeight={'35px'}>
              {apy ? CurrencyAmount.ether(JSBI.multiply(JSBI.BigInt(apy), JSBI.BigInt('100'))).toFixed(0) : '--'} %
            </Typography>
            <Stack ml={58} direction={'row'} spacing={24}>
              <ExternalLink href="https://discord.gg/zCvzPYgGWj">
                <Image width={34} src={discord} />
              </ExternalLink>
              <ExternalLink href="https://t.me/RamBox">
                <Image width={34} src={telegram} />
              </ExternalLink>
              <ExternalLink href="https://twitter.com/0xRamBox">
                <Image width={34} src={twitter} />
              </ExternalLink>
            </Stack>
          </Stack>
        </Box>
      </TopFrame>
      <Box width={1248} sx={{ display: 'flex', margin: '0 auto', position: 'relative', zIndex: 10 }}>
        {/* <Title style={{ marginTop: 117, marginBottom: 30 }}>{t('home.video')}</Title> */}
        <Stack position={'relative'} width={626} height={365} margin={'auto'}>
          {/* <Box sx={{ position: 'absolute', top: 0, left: 0, zIndex: 9 }}>
            <Image width={626} height={365} src={videoModal} />
          </Box> */}
          <video
            muted
            autoPlay
            style={{
              position: 'absolute',
              left: '8px',
              top: '14px',
              width: '626px',
              height: '365px',
              objectFit: 'fill',
              padding: '20px 11px 16px 13px',
              backgroundImage: `url(${videoModal})`,
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat'
            }}
            preload="auto"
            loop
            controls
          >
            <source src={'https://backend.ramx.tech/download/rambox.mp4'} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        </Stack>
        <Stack width={626} height={365} margin={'auto'} paddingLeft={56}>
          <Typography
            width={626}
            // fontFamily={'PhosphateSolid!important'}
            fontWeight={700}
            fontSize={64}
            color={'#000'}
            fontStyle={'italic'}
          >
            {t('romBox_title')}
          </Typography>
          <Typography width={626} fontWeight={400} fontSize={16} color={'#000'} lineHeight={'32px'} marginTop={22}>
            {t('romBox_desc')}
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ width: '100%', marginTop: '-110px', position: 'relative', zIndex: 9 }}>
        <Image width={'100%'} src={ramBoxText} />
      </Box>
      <Box sx={{ width: '100%', height: '736px', position: 'relative' }}>
        <Box sx={{ width: '1248px', margin: '94px auto 72px' }}>
          <Title>{'PRODUCTS'}</Title>
        </Box>
        <Stack direction={'column'} spacing={26}>
          <ProductItem index={0} title={t('NFTfiVault')} icon={productItem1}></ProductItem>
          <ProductItem index={1} title={t('ContributorNFT')} icon={productItem2}></ProductItem>
          <ProductItem index={2} title={t('RamboxNetwork')} icon={productItem3}></ProductItem>
        </Stack>
        <Box sx={{ width: '382px', position: 'absolute', right: 0, top: 45 }}>
          <Image width={'100%'} src={storyLeft} />
        </Box>
      </Box>

      {/* display none */}
      <Box
        margin={'auto'}
        borderRadius={'12px'}
        width={1248}
        padding={38}
        mt={115}
        boxShadow={'0 0 10px 4px #242381 inset'}
        display={'none'}
      >
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Stack spacing={20} direction={'row'}>
            <Image width={82} height={45} src={topImage} />
            <Stack>
              <Typography fontSize={18}>{t('partners.price')}</Typography>
              <Typography fontSize={18} color={'#F9662A'}>
                $0.0025
              </Typography>
            </Stack>
          </Stack>
          <Stack direction={'row'} spacing={26} alignItems={'center'}>
            <Image width={40} height={43} src={coin} />
            <Typography fontSize={18}>{t('partners.buy')}</Typography>
          </Stack>
          <Stack>
            <Stack direction={'row'}>
              <Typography fontSize={18}>{t('partners.totalSupply')} </Typography>
              <Typography fontSize={18} color={'#F9662A'}>
                &nbsp; 6,000,000,000 RAM
              </Typography>
            </Stack>
            <Stack direction={'row'}>
              <Typography fontSize={18}>{t('partners.maxSupply')} </Typography>
              <Typography fontSize={18} color={'#F9662A'}>
                &nbsp;0 RAM
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography fontSize={18}>{t('partners.tvl')}</Typography>
            <Typography textAlign={'center'} fontSize={18} color={'#F9662A'}>
              $0
            </Typography>
          </Stack>
        </Stack>
      </Box>
      {/* $RAM IS THE DRIVING FORCE OF RAMBOX ECOSYSTEM */}
      <Box
        sx={{
          background: `url(${ramBg})`,
          backgroundSize: '100%',
          padding: '79px 0'
        }}
      >
        <Box
          sx={{
            margin: '0 auto 82px',
            width: '100%'
          }}
        >
          <Typography
            fontSize={64}
            lineHeight={'70px'}
            textAlign={'center'}
            color={'#fff'}
            fontWeight={700}
            fontStyle={'italic'}
            fontFamily={'Kanit'}
          >
            <Typography
              fontSize={64}
              lineHeight={'70px'}
              textAlign={'center'}
              color={'#FEF13F'}
              fontWeight={700}
              fontStyle={'italic'}
              fontFamily={'Kanit'}
              mr={'.25em'}
              sx={{
                display: 'inline'
              }}
            >
              $RAM
            </Typography>
            {t('ramIs')}
          </Typography>
          <Typography
            fontSize={64}
            lineHeight={'70px'}
            textAlign={'center'}
            color={'#fff'}
            fontWeight={700}
            fontStyle={'italic'}
            fontFamily={'Kanit'}
          >
            {t('ramIs_2')}
          </Typography>
        </Box>
        <Stack direction={'row'} width={1248} margin={'0 auto 68px'} justifyContent={'space-between'}>
          <Box
            sx={{
              flex: 1
            }}
          >
            <Box
              sx={{
                width: 79,
                margin: '0 auto 27px'
              }}
            >
              <Image width={79} src={ramICon1}></Image>
            </Box>
            <Typography fontFamily={'Kanit'} fontSize={24} lineHeight={'32px'} color={'#fff'} textAlign={'center'}>
              {t('ramIs_desc_1')}
            </Typography>
          </Box>

          <Box
            sx={{
              flex: 1
            }}
          >
            <Box
              sx={{
                width: 79,
                margin: '0 auto 27px'
              }}
            >
              <Image width={79} src={ramICon2}></Image>
            </Box>
            <Typography fontFamily={'Kanit'} fontSize={24} lineHeight={'32px'} color={'#fff'} textAlign={'center'}>
              {t('ramIs_desc_2')}
            </Typography>
          </Box>

          <Box
            sx={{
              flex: 1
            }}
          >
            <Box
              sx={{
                width: 79,
                margin: '0 auto 27px'
              }}
            >
              <Image width={79} src={ramICon3}></Image>
            </Box>
            <Typography fontFamily={'Kanit'} fontSize={24} lineHeight={'32px'} color={'#fff'} textAlign={'center'}>
              {t('ramIs_desc_3')}
            </Typography>
          </Box>

          <Box
            sx={{
              flex: 1
            }}
          >
            <Box
              sx={{
                width: 79,
                margin: '0 auto 27px'
              }}
            >
              <Image width={79} src={ramICon4}></Image>
            </Box>
            <Typography fontFamily={'Kanit'} fontSize={24} lineHeight={'32px'} color={'#fff'} textAlign={'center'}>
              {t('ramIs_desc_4')}
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{
            width: 224,
            height: 50,
            margin: '0 auto'
          }}
        >
          <ExternalLink href="https://www.mexc.com/exchange/RAM_USDT" style={{ textDecoration: 'none' }}>
            <GetButton>{'GET $RAM NOW'}</GetButton>
          </ExternalLink>
        </Box>
      </Box>
      {/* roadMap */}
      <Box marginTop={77} paddingBottom={73}>
        <Box
          sx={{
            margin: '0 auto',
            width: 322
          }}
        >
          <Title textAlign={'center'} id={'roadmap'}>
            {t('roadmap.title')}
          </Title>
        </Box>
        {/*  */}
        <Stack
          margin={'auto'}
          mt={60}
          spacing={23}
          padding={'0 0'}
          width={1248}
          justifyContent={'space-between'}
          direction={'row'}
        >
          {roadMapList[0].map((item, index) => {
            return (
              <Box
                sx={{
                  border: '2px solid #000000',
                  boxShadow: '-4px 4px 0px #FEF13F',
                  padding: '27px 12px',
                  width: 'calc(1207px / 4)',
                  height: 260
                }}
                key={index + 's'}
              >
                <Typography
                  marginBottom={13}
                  textAlign={'center'}
                  fontFamily={'Kanit'}
                  fontWeight={700}
                  fontSize={24}
                  color={'#000'}
                >
                  {roadMapTitleList[0][index]}
                </Typography>
                <Stack width={'100%'} spacing={5} key={index}>
                  {item.map(subItem => {
                    return (
                      <Typography
                        sx={{
                          '&::before': {
                            content: '"."',
                            fontSize: 42,
                            color: '#000',
                            lineHeight: 0.2,
                            position: 'absolute',
                            marginTop: -8,
                            marginLeft: -16,
                            fontFamily: 'Roboto !important'
                          }
                        }}
                        paddingLeft={16}
                        lineHeight={'25px'}
                        fontSize={16}
                        key={index + 's'}
                      >
                        {subItem}
                      </Typography>
                    )
                  })}
                </Stack>
              </Box>
            )
          })}
        </Stack>
        <Stack
          margin={'auto'}
          mt={60}
          spacing={23}
          padding={'0 0'}
          width={1248}
          justifyContent={'space-between'}
          direction={'row'}
        >
          {roadMapList[1].map((item, index) => {
            return (
              <Box
                sx={{
                  border: '2px solid #000000',
                  boxShadow: '-4px 4px 0px #FEF13F',
                  padding: '27px 12px',
                  width: 'calc(1207px / 4)',
                  height: 470
                }}
                key={index + 's'}
              >
                <Typography
                  marginBottom={13}
                  textAlign={'center'}
                  fontFamily={'Kanit'}
                  fontWeight={700}
                  fontSize={24}
                  color={'#000'}
                >
                  {roadMapTitleList[1][index]}
                </Typography>
                <Stack width={'100%'} spacing={5} key={index}>
                  {item.map(subItem => {
                    return (
                      <Typography
                        sx={{
                          '&::before': {
                            content: '"."',
                            fontSize: 42,
                            color: '#000',
                            lineHeight: 0.2,
                            position: 'absolute',
                            marginTop: -4,
                            marginLeft: -16,
                            fontFamily: 'Roboto !important'
                          }
                        }}
                        paddingLeft={16}
                        lineHeight={'25px'}
                        fontSize={16}
                        key={index + 's'}
                      >
                        {subItem}
                      </Typography>
                    )
                  })}
                </Stack>
              </Box>
            )
          })}
        </Stack>
      </Box>
      {/* Investors & Partners */}
      <Box
        sx={{
          background: `url(${bottomBg})`,
          paddingTop: 45,
          paddingBottom: 43,
          backgroundSize: '100%'
        }}
      >
        <Box
          sx={{
            margin: '0 auto 82px',
            width: 731
          }}
        >
          <Title textAlign={'center'}>{t('InPa')}</Title>
        </Box>
        <Stack
          sx={{
            flexWrap: 'wrap'
          }}
          direction={'row'}
          justifyContent={'space-between'}
          // spacing={28}
          width={1248}
          margin={'0 auto'}
        >
          {logoList.map((item, index) => {
            console.log(item, 'item')

            return (
              <Box key={index}>
                <Image width={'auto'} height={90} src={item.default} />
              </Box>
            )
          })}
          <Box width={260} height={90}></Box>
          <Box width={260} height={90}></Box>
        </Stack>

        <Typography fontSize={16} lineHeight={'32px'} mt={41} textAlign={'center'} color={'#000'}>
          Copyright 2023 - RamBox All right reserved
        </Typography>
      </Box>
    </Box>
  )
}
