import { useState, useCallback } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { AppBar, Box, Stack, styled as muiStyled, styled } from '@mui/material'
import { ExternalLink } from 'theme/components'
import Web3Status from './Web3Status'
import { HideOnMobile, ShowOnMobile } from 'theme/index'
// import Image from 'components/Image'
// import logo from '../../assets/svg/chain_swap.svg'
import { routes } from 'constants/routes'
import MobileMenu from './MobileMenu'
import Image from '../Image'
// import NetworkSelect from './NetworkSelect'
import gitbook from '../../assets/images/gitbook.png'
import telegram from '../../assets/images/telegram.png'
import telegramDark from '../../assets/images/telegram_dark.png'
import twitter from '../../assets/images/twitter.png'
import twitterDark from '../../assets/images/twitter_dark.png'
import medium from '../../assets/images/medium.png'
import discord from '../../assets/images/discord.png'
import discordDark from '../../assets/images/discord_dark.png'
import menu from '../../assets/images/menu.png'
import topImage from '../../assets/images/top-img.png'
import topImageM from '../../assets/images/top-img-m.png'
import Lang from './Lang'
import { scrollToElement } from '../../utils'
import { useI18n } from 'react-simple-i18n'

interface TabContent {
  title: string
  route?: string
  link?: string
  id?: string
  titleContent?: JSX.Element
}

interface Tab extends TabContent {
  subTab?: TabContent[]
}

export function useTabs(): Tab[] {
  const { t, i18n } = useI18n()
  console.log(t)
  console.log(i18n.getLang(), 'i18n')

  return [
    { title: t('Home'), route: routes.RamBox },
    { title: 'MINT NFT', route: routes.mintNFT },
    { title: t('VAULT'), route: routes.vault },
    { title: 'STAKE', route: routes.stake },
    { title: 'WALLET', route: routes.wallet }
    // { title: 'IDO', route: routes.IDO },
    // { title: t('marketplace'), route: routes.Marketplace, id: 'marketplace' },
    // { title: t('roadmap.title'), route: routes.Roadmap, id: 'roadmap' }
    // { title: t('tokenomics1'), route: routes.Tokenomics, id: 'tokenomics' },
    // { title: 'DAO', link: '/' }
    // { title: 'Referral Program', route: routes.test }
    // { title: 'DAO', link: 'https://dao.logo.finance/#/' },
    // { title: 'Docs', link: 'https://docs.logo.finance/' }
  ]
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  width: '1248px',
  position: 'relative',
  height: theme.height.header,
  backgroundColor: 'rgba(0, 0, 0, 0)',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: 'none',
  margin: '0 auto -122px',
  zIndex: theme.zIndex.drawer,

  '& .link': {
    textDecoration: 'none',
    fontSize: 14,
    color: '#fff',
    // opacity: 0.5,
    marginRight: 71.5,
    '&.active': {
      opacity: 1,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main
    },
    '&:hover': {
      opacity: 1,
      color: theme.palette.primary.main
    }
  },
  '& .dark': {
    color: '#000',
    '&.active': {
      color: '#000'
    },
    '&:hover': {
      color: '#000'
    }
  },
  '& .lr': {},
  [theme.breakpoints.down('lg')]: {
    '& .link': { marginRight: 15 },
    padding: '0 24px 0 0!important'
  },
  [theme.breakpoints.down('md')]: {
    position: 'fixed'
  },
  [theme.breakpoints.down('sm')]: {
    height: theme.height.mobileHeader,
    padding: '0 20px!important'
  }
}))

const Filler = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    height: theme.height.header,
    display: 'block'
  },
  [theme.breakpoints.down('sm')]: {
    height: theme.height.mobileHeader,
    padding: '0 20px'
  }
}))

const MainLogo = styled(NavLink)(({ theme }) => ({
  '& img': {
    width: 180,
    height: 112
  },
  '&:hover': {
    cursor: 'pointer'
  },
  [theme.breakpoints.down('sm')]: {
    '& img': { width: 100, height: 'auto' },
    marginBottom: -10
  }
}))

const LinksWrapper = muiStyled('div')(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  display: 'flex',
  marginLeft: 46,
  justifyContent: 'space-around',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    marginLeft: 0
  }
}))

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { pathname } = useLocation()
  console.log(pathname, 'pathname', pathname.includes('home'))

  const { t, i18n } = useI18n()
  console.log(t)
  console.log(i18n.getLang(), 'i18n')
  const tabs = useTabs()
  const handleMobileMenueDismiss = useCallback(() => {
    setTimeout(() => {
      setMobileMenuOpen(false)
    }, 500)
  }, [])

  return (
    <>
      <MobileMenu isOpen={mobileMenuOpen} onDismiss={handleMobileMenueDismiss} />
      <Filler />
      <StyledAppBar>
        <ShowOnMobile breakpoint="md">
          <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
            <Web3Status />
            <Stack paddingTop={2} marginLeft={28} direction={'row'} spacing={20} alignItems={'center'}>
              <ExternalLink style={{ height: 18 }} href="https://rambox.gitbook.io/doc/">
                <Image width={18} src={gitbook} />
              </ExternalLink>
              <ExternalLink style={{ height: 18 }} href="https://medium.com/@0xRamBox">
                <Image width={18} src={medium} />
              </ExternalLink>
              <ExternalLink style={{ height: 18 }} href="https://t.me/RamBox">
                <Image width={18} src={telegram} />
              </ExternalLink>
              <ExternalLink style={{ height: 18 }} href="https://twitter.com/0xRamBox">
                <Image width={18} src={twitter} />
              </ExternalLink>
              <ExternalLink style={{ height: 18 }} href="https://discord.gg/zCvzPYgGWj">
                <Image width={18} src={discord} />
              </ExternalLink>
            </Stack>
          </Stack>
        </ShowOnMobile>
        <HideOnMobile className="lr"></HideOnMobile>

        <Box display="flex" alignItems="center" width={'100%'}>
          <MainLogo id={'/'} to={'/'}>
            <Image src={topImage} alt={'chainswap'} />
          </MainLogo>
          <HideOnMobile breakpoint="md">
            <LinksWrapper>
              {tabs.map(({ title, route, titleContent, id }, idx) => (
                <NavLink
                  onClick={() => {
                    if (id) scrollToElement(id)
                  }}
                  style={{
                    height: 80,
                    lineHeight: '80px',
                    fontFamily: `${i18n.getLang() === 'cn' ? 'NotoSansSC' : ''}`
                  }}
                  key={title + idx}
                  id={`${route}-nav-link`}
                  to={route ? (route === '/vault' ? '/vault' : route) : ''}
                  className={
                    (route
                      ? pathname.includes(route)
                        ? 'active'
                        : pathname.includes('account')
                        ? route.includes('account')
                          ? 'active'
                          : ''
                        : ''
                      : '') + (pathname.includes('home') ? ' link normal' : ' link dark')
                  }
                >
                  {titleContent ?? title}
                </NavLink>
              ))}
            </LinksWrapper>
          </HideOnMobile>
        </Box>

        <HideOnMobile>
          <Stack paddingTop={2} marginRight={32} direction={'row'} spacing={20} alignItems={'center'}>
            <ExternalLink style={{ height: 26 }} href="https://discord.gg/zCvzPYgGWj">
              {pathname.includes('home') ? <Image width={26} src={discord} /> : <Image width={26} src={discordDark} />}
            </ExternalLink>
            <ExternalLink style={{ height: 26 }} href="https://t.me/RamBox">
              {pathname.includes('home') ? (
                <Image width={26} src={telegram} />
              ) : (
                <Image width={26} src={telegramDark} />
              )}
            </ExternalLink>
            <ExternalLink style={{ height: 26 }} href="https://twitter.com/0xRamBox">
              {pathname.includes('home') ? <Image width={26} src={twitter} /> : <Image width={26} src={twitterDark} />}
            </ExternalLink>
          </Stack>
        </HideOnMobile>
        <HideOnMobile>
          <Lang />
        </HideOnMobile>
        <Box className="lr" display="flex" alignItems="center" gap={{ xs: '6px', sm: '20px' }}>
          {/* <NetworkSelect /> */}
          <HideOnMobile>
            <Web3Status />
          </HideOnMobile>
          <ShowOnMobile breakpoint="md">
            <Stack width={'100%'} direction={'row'} alignItems={'center'}>
              <MainLogo id={'/'} to={'/'}>
                <Image src={topImageM} alt={'chainswap'} />
              </MainLogo>
              <Lang />
              <Stack
                marginLeft={12}
                onClick={() => {
                  setMobileMenuOpen(true)
                }}
              >
                <Image width={18} src={menu} />
              </Stack>
            </Stack>
          </ShowOnMobile>
        </Box>
      </StyledAppBar>
    </>
  )
}
