import { Stack, styled, Typography, Box } from '@mui/material'
import { isMobile } from 'react-device-detect'
import Image from '../../components/Image'
import mintNFTImg from '../../assets/images/book-z.png'
import { useI18n } from 'react-simple-i18n'
import Button from 'components/Button/Button'
import { useActiveWeb3React } from '../../hooks'
import { useRambox, useGetTotalCost } from '../../hooks/useRambox'
import { useState, useCallback, useMemo } from 'react'
import TransactionSubmittedModal from '../../components/Modal/TransactionModals/TransactiontionSubmittedModal'
import TransactionPendingModal from 'components/Modal/TransactionModals/TransactionPendingModal'
import useModal from '../../hooks/useModal'
import MessageBox from '../../components/Modal/TransactionModals/MessageBox'
import { useApproveCallback } from 'hooks/useApproveCallback'
import Web3 from 'web3'
import { useWalletModalToggle } from 'state/application/hooks'
import { RAMNFT } from '../../constants'
import topImage from '../../assets/images/top-img.png'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import useCountdown from 'hooks/useCountdown'
const MainLogoPage = styled(NavLink)(({ theme }) => ({
  position: 'absolute',
  left: 20,
  top: -3,
  '& img': {
    width: 180,
    height: 112
  },
  '&:hover': {
    cursor: 'pointer'
  },
  [theme.breakpoints.down('sm')]: {
    '& img': { width: 137, height: 'auto' },
    marginBottom: -10
  }
}))

const IDOFrame = styled('div')`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 149px 0 50px 0;
  min-height: 100vh;
`
const Title = styled(Typography)`
  font-size: 40px;
  font-family: 'Kanit';
  font-style: italic;
  color: #000;
  font-weight: 700;
  margin: 0 25px 28px 25px;
`

export default function MintNFT() {
  const toggleWalletModal = useWalletModalToggle()
  const { t } = useI18n()
  const { account, chainId } = useActiveWeb3React()
  console.log(account, chainId, 'useActiveWeb3React')
  const { showModal, hideModal } = useModal()
  const [count, setCount] = useState(1)
  console.log(setCount, 'setCount')

  const { price, mostCountPerAccount, mint, mintActive } = useRambox()
  const totalCost = useGetTotalCost(count)
  const closeTime = new Date('2023-07-28 20:30:00').getTime()
  const startTime = new Date('2023-07-28 20:00:00').getTime()
  const [buyApprovalState, buyApprovalCallback] = useApproveCallback(totalCost, RAMNFT[chainId ?? 56])
  console.log(buyApprovalState, buyApprovalCallback)

  const mintCallback = useCallback(async () => {
    if (!account || !count) return
    showModal(<TransactionPendingModal />)
    mint(count)
      .then(() => {
        hideModal()
        showModal(<TransactionSubmittedModal />)
      })
      .catch((err: any) => {
        hideModal()
        showModal(
          <MessageBox type="error">{err.error && err.error.message ? err.error.message : err?.message}</MessageBox>
        )
        console.error(err)
      })
  }, [account, count, showModal, mint, hideModal])

  const deadline = useMemo(
    () =>
      moment('2023-07-28 20:00:00')
        // 设置时间
        .format('YYYY-MM-DD HH:mm:ss'),
    []
  )
  const { hour, minute, second } = useCountdown({
    deadline
  })
  const list = [
    {
      title: t('mintNFT_1'),
      desc: t('mintNFT_1_desc')
    },
    {
      title: t('mintNFT_2'),
      desc: t('mintNFT_2_desc')
    },
    {
      title: t('mintNFT_3'),
      desc: t('mintNFT_3_desc')
    },
    {
      title: t('mintNFT_4'),
      desc: t('mintNFT_4_desc')
    },
    {
      title: t('mintNFT_5'),
      desc: t('mintNFT_5_desc')
    }
  ]
  const NFTButtom = useMemo(() => {
    if (new Date().getTime() > closeTime || (new Date().getTime() > startTime && !(mintActive === true))) {
      return (
        <>
          <Button
            disabled
            onClick={() => {}}
            style={{
              width: 'calc(100% - 50px)',
              height: '47px',
              margin: '0 auto'
            }}
          >
            End
          </Button>
        </>
      )
    }
    if (!account) {
      return (
        <>
          <Button
            disabled={!(hour === 0 && minute === 0 && second === 0)}
            onClick={toggleWalletModal}
            style={{
              width: 'calc(100% - 50px)',
              height: '47px',
              margin: '0 auto'
            }}
          >
            Connect Wallet
          </Button>
        </>
      )
    }

    if (!totalCost) {
      return (
        <>
          <Button
            disabled
            style={{
              width: 'calc(100% - 50px)',
              height: '47px',
              margin: '0 auto'
            }}
          >
            Loading
          </Button>
        </>
      )
    }
    if (buyApprovalState !== 3) {
      if (buyApprovalState === 2) {
        return (
          <>
            <Button
              disabled
              style={{
                width: 'calc(100% - 50px)',
                height: '47px',
                margin: '0 auto'
              }}
            >
              Approving
            </Button>
          </>
        )
      } else if (buyApprovalState === 1) {
        return (
          <>
            <Button
              disabled={!(hour === 0 && minute === 0 && second === 0)}
              onClick={buyApprovalCallback}
              style={{
                width: 'calc(100% - 50px)',
                height: '47px',
                margin: '0 auto'
              }}
            >
              Approval
            </Button>
          </>
        )
      } else {
        return (
          <>
            <Button
              style={{
                width: 'calc(100% - 50px)',
                height: '47px',
                margin: '0 auto'
              }}
              disabled
            >
              Loading...
            </Button>
          </>
        )
      }
    }

    return (
      <>
        <Button
          onClick={mintCallback}
          disabled={!(hour === 0 && minute === 0 && second === 0)}
          style={{
            width: 'calc(100% - 50px)',
            height: '47px',
            margin: '0 auto'
          }}
        >
          Mint
        </Button>
      </>
    )
  }, [
    account,
    buyApprovalState,
    totalCost,
    mintCallback,
    buyApprovalCallback,
    toggleWalletModal,
    hour,
    minute,
    second,
    closeTime,
    startTime,
    mintActive
  ])

  function NFTButtomFun() {
    return NFTButtom
  }
  return (
    <IDOFrame
      sx={{
        width: isMobile ? '100%' : '1248px'
      }}
    >
      <MainLogoPage id={'/'} to={'/'}>
        <Image src={topImage} alt={'chainswap'} />
      </MainLogoPage>
      <Stack
        sx={{
          position: 'relative',
          zIndex: 10
        }}
        width={'100%'}
        direction={'column'}
        justifyContent={'space-between'}
      >
        <Title>Mint NFT</Title>
        <Stack
          sx={{
            margin: '25px auto 25px'
          }}
          width={'360px'}
        >
          <Image src={mintNFTImg}></Image>
        </Stack>
        <Stack
          sx={{
            margin: '0 auto',
            justifyContent: 'space-between',
            marginBottom: '20px',
            width: '100%',
            padding: '0 25px'
          }}
          marginTop={19}
          direction={'row'}
        >
          <Typography width={90} fontSize={16} lineHeight={'32px'}>
            {t('nft.name')}
          </Typography>
          <Typography fontWeight={700} fontSize={24} lineHeight={'32px'}>
            Contributor NFT
          </Typography>
        </Stack>
        <Stack
          sx={{
            margin: '0 auto',
            justifyContent: 'space-between',
            marginBottom: '20px',
            width: '100%',
            padding: '0 25px'
          }}
          direction={'row'}
          spacing={70}
        >
          <Typography width={90} fontSize={16} lineHeight={'32px'}>
            {t('nft.price')}
          </Typography>
          <Typography fontWeight={700} fontSize={24} lineHeight={'32px'}>
            {price ? Web3.utils.fromWei(price, 'ether') : '--'} $USDT
          </Typography>
        </Stack>
        <Stack
          sx={{
            margin: '0 auto',
            justifyContent: 'space-between',
            marginBottom: '20px',
            width: '100%',
            padding: '0 25px'
          }}
          direction={'row'}
          spacing={70}
        >
          <Typography width={90} fontSize={16} lineHeight={'32px'}>
            {t('nft.Quantity')}
          </Typography>
          {new Date().getTime() > closeTime || (new Date().getTime() > startTime && !(mintActive === true)) ? (
            <Typography fontWeight={700} fontSize={24} lineHeight={'32px'}>
              --
            </Typography>
          ) : (
            <Typography fontWeight={700} fontSize={24} lineHeight={'32px'}>
              300
            </Typography>
          )}
        </Stack>
        <Stack
          sx={{
            margin: '0 auto',
            justifyContent: 'space-between',
            marginBottom: '20px',
            width: '100%',
            padding: '0 25px'
          }}
          direction={'row'}
          spacing={70}
        >
          <Typography width={90} fontSize={16} lineHeight={'32px'}>
            {t('nft.Amount')}
          </Typography>
          <Stack
            direction={'row'}
            sx={{
              width: 227,
              height: 43,
              border: '2px solid #FEF13F'
            }}
          >
            <Button
              disabled={
                !(hour === 0 && minute === 0 && second === 0) ||
                count == 1 ||
                new Date().getTime() > closeTime ||
                (new Date().getTime() > startTime && !(mintActive === true))
              }
              onClick={() => setCount(count - 1)}
              style={{
                width: 43,
                height: 39,
                background: '#fef13f',
                fontSize: 40,
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: 0
              }}
            >
              -
            </Button>
            <Box
              sx={{
                flex: 1,
                textAlign: 'center',
                lineHeight: '39px',
                fontSize: 24
              }}
            >
              {count}
            </Box>
            <Button
              onClick={() => setCount(count + 1)}
              disabled={
                !(hour === 0 && minute === 0 && second === 0) ||
                count == mostCountPerAccount ||
                new Date().getTime() > closeTime ||
                (new Date().getTime() > startTime && !(mintActive === true))
              }
              style={{
                width: 43,
                height: 39,
                background: '#fef13f',
                fontSize: 40,
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: 0
              }}
            >
              +
            </Button>
          </Stack>
        </Stack>
        <NFTButtomFun />
        {!(hour === 0 && minute === 0 && second === 0) && (
          <Box
            sx={{
              textAlign: 'center',
              border: '1px solid #FEF13F',
              height: 47,
              fontSize: 22,
              lineHeight: '45px',
              borderRadius: '10px',
              width: 'calc(100% - 50px)',
              margin: '20px auto 0!important'
            }}
          >
            {hour < 10 ? '0' + hour : hour}:{minute < 10 ? '0' + minute : minute}:{second < 10 ? '0' + second : second}
          </Box>
        )}
      </Stack>
      <Stack
        sx={{
          position: 'relative',
          zIndex: 10,
          padding: '0 25px'
        }}
        spacing={28}
        direction={'column'}
        marginTop={78}
        width={'100%'}
      >
        <Stack width={'100%'}>
          <Typography
            sx={{
              borderLeft: '5px solid #FEF13F'
            }}
            paddingLeft={34}
            fontSize={18}
            fontWeight={600}
            lineHeight={'32px'}
          >
            {t('mintNFT_desc')}
          </Typography>
        </Stack>
        <Stack width={'100%'} spacing={20}>
          {list.map((item, index) => {
            return (
              <Box sx={{}} key={index + 's'}>
                <Typography fontSize={16} fontWeight={700} lineHeight={'24px'}>
                  {item.title}
                </Typography>
                <Typography fontSize={16} fontWeight={400} lineHeight={'24px'}>
                  {item.desc}
                </Typography>
              </Box>
            )
          })}
        </Stack>
      </Stack>
    </IDOFrame>
  )
}
