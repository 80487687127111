export const cn = {
  home: {
    title: '首个NFTFi聚合协议，倡导F2P2E（免费玩赚)',
    stakingAPY: 'Staking APY',
    holders: '通证持有人数量',
    followers: 'Twitter粉丝',
    members: 'Telegram成员',
    video: '项目视频'
  },
  video: {
    title: '项目视频'
  },
  projects: {
    title: 'RamBox产品',
    subTitle1: '游戏聚合',
    subTitle2: '市场',
    subTitle3: 'DAO',
    content1:
      '鼓励优秀的GameFi团队在Gamelist上发布可玩性高、通证经济合理的GameFi项目，用户通过DAO（去中心化自律组织）投票决定是否上线项目。',
    content2:
      '用户可以在市场上购买/出售各种游戏NFT。包括角色NFT、道具NFT等，Rambox用户可以享受到 "游戏价值资本化 "所带来的便利和财富效应。',
    content3: 'RamBox平台支持RAM通证持有者自发组建DAO，进行社区治理，11Ram代币可投1票。DAO可以决定以下内容...'
  },
  tokenomics: {
    title: 'RAM通证经济',
    subTitle: 'RAM是RamBox生态系统的驱动力。',
    text1: '1.用户可以在RAMSWAP模块中质押RAM，获得RAM通证奖励。\n',
    text2: '2.用户可以在RAMSWAP模块中质押RAM-USDT的流动对，获得RAM通证奖励。\n',
    text3: '3.用户可以在GameList模块中参与各种游戏。RAM的应用场景贯穿所有游戏的经济模型中。\n',
    text4: '当用户在市场上购买/出售NFT、质押NFT或生产NFT合成资产时，可以使用RAM作为媒介，获得RAM代币奖励。',
    totalSupply: '总供应量'
  },
  partners: {
    title: '投资者和合作伙伴',
    price: 'RAM价格',
    buy: '买 RAM',
    totalSupply: '总供应量:',
    maxSupply: '最大供应量:',
    tvl: '锁定的总价值'
  },
  roadmap: {
    title: '路线图',
    r1: '2022第二季度',
    r2: '2022第三季度',
    r3: '2022第四季度',
    r4: '2023第一季度',
    r5: '2023第二季度',
    r6: '2023第三季度',
    t1: '团队建设',
    t2: '项目定位和愿景',
    t3: '经济模式',
    t4: '网站',
    t5: '白皮书',
    t6: 'NFT设计',
    t7: 'RAM IDO',
    t8: '推出NFT',
    t9: 'RAM公开发行',
    t10: 'NFT权益激励池',
    t11: '创建流动池',
    t12: 'Rambox DAO',
    t13: 'Ram链公测',
    t14: '创建NFT权益社区',
    t15: '启动Ram链',
    t16: '开启节点激励',
    t17: 'NFT升级',
    t18: 'Ram链DAO',
    t19: 'RAM生态基金'
  },
  roadMapV2: {
    title: 'Roadmap',
    r1: '2021年Q1-Q3',
    r2: '2022年Q2',
    r3: '2022年Q3',
    r4: '2022年Q4',
    r5: '2023年Q1',
    r6: '2023年Q2',
    r7: '2023年Q3-Q4',
    r8: '2024年Q1-Q2',
    r1_1: 'Rambox自动化收益机枪池研发启动',
    r1_2: 'Rambox聚合协议研发启动',
    r1_3: 'Rambox项目白皮书启动',
    r2_1: 'Rambox核心技术研发结束，并实现超500%APY',
    r2_2: 'RAM NETWORK研发启动',
    r3_1: '项目市场化筹备',
    r3_2: 'NFTfi & Gamefi自动化收益系统启动设计',
    r3_3: 'RAM纪念NFT系列启动设计',
    r4_1: 'Rambox平台正式上线',
    r4_2: '机枪池功能上线',
    r5_1: 'Rambox 公链机枪池战略发展正式启动',
    r5_2: 'Rambox 筹备交易所上线事宜',
    r6_1: 'Rambox 品牌战略升级',
    r6_2: '新聚合产品上线问世',
    r6_3: '支持BRC20、BRC721资产交易',
    r6_4: '支持BTC L2公链流动性捕获',
    r6_5: '上线Rambox NFT系列产品',
    r6_6: '启动全球市场运营活动',
    r6_7: '上线Rambox独特钱包应用产品',
    r7_1: 'Rambox AI 服务器部署到全球范围',
    r7_2: '正式开启Rambox全链流动性聚合功能',
    r7_3: '与RWA企业共同建设Web2 to Web3的流动性服务',
    r8_1: 'Rambox上线自研公链系统',
    r8_2: '开放Rambox节点网络',
    r8_3: '开启Rambox节点质押生态'
  },
  token: '代币',
  saleRule: '公平发售 - 最大买入200 USDT',
  tops: '软顶/硬顶',
  progress: '进度',
  left: 'RAM剩余总量',
  total: 'RAM总量',
  myRAM: '我的RAM令牌',
  lockup: '锁定时间',
  start: '销售开始于',
  preAddress: '预售地址',
  name: '代币名称',
  symbol: '代币符号',
  decimals: '令牌小数',
  tokenAddress: '代币地址',
  tip: '(请不要发送任何资产到合约地址！)',
  supply: '总供应量',
  presale: '用于预售的代币',
  cap: '初始市值(估计)',
  soft: '软顶',
  hard: '硬顶',
  startTime: '预售开始时间',
  endTime: '预售结束时间',
  list: '上架交易所',
  liquidityPercent: '流动性占比',
  liquidityLockupTime: '流动性锁定时间',
  days: '9天',
  enterAmount: '输入金额',
  max: '最大金额为200',
  allow: '授权 Rambox 使用你的',
  buy: '购买',
  approving: '正在授权',
  with: '使用了',
  marketplace: '市场',
  tokenomics1: '通证经济',
  insufficient: '余额不足',
  End: '结束',
  enterInviter: '输入邀请人的钱包地址',
  bind: '绑定',
  notInviter: '推荐地址无效',
  inviteLink: '推广链接',
  totalLock: '存款总量',
  stakedAmount: '已经质押',
  claimAbleAmount: '可取收益',
  apy: '综合年化',
  save: '存入',
  withdraw: '提取收益',
  claimReward: '提取收益',
  exit: '全部提取',
  stakingTip: '提示',
  tip1: '1、领取收益包含当前全部收益，原存入RAM不受影响',
  tip2: '2、取回RAM并领取收益包含取回全部存入RAM和全部收益\n',
  tip3: '3、取回包含取回存入RAM以及当前可取回的全部收益',
  tip4:
    '存入RAM，根据快照的RAM数量进行收益分配。\n' +
    '      所有收益领取扣除10%手续费进行销毁（存入的RAM取回不扣除任何手续费）',
  attention: '重要',
  balance: '可取余额',
  Max: '最大',
  contribution: '贡献值',
  myInviteLink: '我的邀请链接:',
  VAULT: '机枪池',
  Home: '首页',
  gotovault: '前往机枪池',
  entervault: '前往机枪池',
  productDesc1:
    'NFTfi机枪池是定位于专注NFTfi流动性聚合协议，是一种自动化NFTfi收益机枪池运作系统，Rambox是一项专注NFT领域的资产管理协议，试图为依赖自动化投资的未来构建基础设施，专注于通过在不同NFTfi协议之间自动切换来最大化收益能力。与传统NFTfi不同，Rambox活跃于各种可能、可以产生收益的NFT项目与NFT流动性服务。目前Rambox已经可以多种NFTfi产品和跨链生态。',
  productDesc3: `所有持有RAM的用户都将可以参与Rambox提供的数字科技服务器，并成为其中的验证节点。每个参与管理、运行的验证节点都将直接获益整个项目的全部收益，收益的规模与用户参与验证的节点数量正相关。
  并且Rambox Network的角色将取代国库，所有Rambox平台收益的部分，将有Rambox Network完全取代。
  Rambox提出的数字科技服务中的验证节点将成为上最大的去中心化NFT网络的一部分，这些节点永远只有 30,000 个，成为节点需要质押100,000个RAM，以锁定验证节点资格，成为验证节点获得机枪池所有收益。`,
  NFTfiVault: 'NFTfi 机枪池',
  ContributorNFT: '贡献者NFT',
  RamboxNetwork: 'Rambox Network',
  prod3_1: '模块化区块链',
  prod3_2: '云端服务器',
  prod3_3: '支持EVM',
  prod3_4: '跨连万物',
  comingSoon: '即将到来',
  prod2_1: '基础算力',
  prod2_2: '价值成长',
  prod2_3: 'DAO治理',
  prod2_4: '.升值空间',
  prod2_5: ' RAM升级',
  prod2_1_desc: '每个贡献者NFT将会具备一定的机枪池算力，会获得额外贡献值加成',
  prod2_2_desc: '贡献者NFT的收益能力受NFT持有者的生态贡献值影响',
  prod2_3_desc: '贡献者NFT持有者可获得参与未来DAO的提案权限',
  prod2_4_desc: '贡献者NFT在二级市场拥有无限的升值空间',
  prod2_5_desc: '贡献者NFT持有者的RAM代币将升级AI算力资产，享受更多权益加成',
  romBox_title: '什么是Rambox?',
  romBox_desc:
    'Rambox将专注数字科技在AI商业化领域发展的一切探索，以搭建全球最强、最简化的稳定投资收益平台为己任。目前专注于NFTfi、衍生品、公链资金等流动性服务提供商角色，发挥以AI为技术优势的资金自动化投资策略。\n' +
    '\n未来Rambox将会在NFTfi领域、与模块化公链基础设施为发展重点方向。',
  CSA: '当前质押APY...',
  InPa: '投资者与合作伙伴',
  ramIs: '  是   RamBox 生态系统的驱动力',
  ramIs_2: ' ',
  ramIs_desc_1: '机枪池质押获利',
  ramIs_desc_2: 'NFT交互',
  ramIs_desc_3: '参与Rambox Network',
  ramIs_desc_4: '社区DAO治理',
  yInfo: '你的信息',
  AnnualCompound: '综合年化',
  get: '获取',
  mintNFT_desc:
    '贡献者NFT 不仅拥有一定数量的机枪池算力，还会获得额外的生态贡献值加成，影响其收益能力。贡献者NFT 为所有者提供了参与 DAO 提案的权利，并且在二级市场上具有无限的升值潜力市场。贡献者NFT 持有者的 RAM 代币将升级为 AI 计算能力资产，从而享受更多权益加成。',
  mintNFT_1: '1. 基础算力',
  mintNFT_2: '2. 价值成长',
  mintNFT_3: '3. DAO治理',
  mintNFT_4: '4. 升值空间',
  mintNFT_5: '5. RAM升级',
  mintNFT_1_desc: '每个贡献者NFT将会具备一定的机枪池算力，会获得额外贡献值加成',
  mintNFT_2_desc: '贡献者NFT的收益能力受NFT持有者的生态贡献值影响',
  mintNFT_3_desc: '贡献者NFT持有者可获得参与未来DAO的提案权限',
  mintNFT_4_desc: '贡献者NFT在二级市场拥有无限的升值空间',
  mintNFT_5_desc: '贡献者NFT持有者的RAM代币将升级AI算力资产，享受更多权益加成',
  nft: {
    name: 'NFT名称',
    price: '价格',
    Quantity: '供应量',
    Amount: '数量'
  },
  close: '关闭',
  change: '改变',
  transactionsTips: '您的交易将显示在此处...',
  copyAdd: '复制地址',
  tbd: '待定',
  stake: '质押',
  STAKE: '质押',
  totalRevenue: '总收益',
  claimed: '已提取',
  claim: '提取',
  reward: '待提取',
  signer: '签名',
  wallet: {
    download: '下载',
    desc: '解锁区块链世界的便捷通道',
    sub1: '聚合Rambox资产管理',
    sub2: '一体化Web3体验',
    sub3: '人工智能驱动的资产增长',
    title: 'RAMX 钱包',
    txt: '一站式Web3资产管理与理财',
    t1: '支持多链资产',
    t2: '支持多钱包管理',
    t3: '资产的行情查询和历史记录',
    t4: '支持ENS 域名系统',
    t5: '支持DAPP交互',
    t6: '支持闪兑与0 GAS交易',
    content1:
      'RamX是由Rambox重点研发的简易资产管理平台。它基于Rambox的核心功能，进一步简化了用户帐户与资产的管理流程。RamX不仅融合了DEX swap、跨链交易功能，还提供了一站式流动性供应解决方案。',
    content2:
      '通过RamX，用户可以更轻松地降低Web3的操作难度，更方便地一站式体验Web3生态的门户产品。此外，RamX还能够为用户提供基于流动性价值的加密资产理财投资产品，帮助用户实现资产的最大化增值。',
    m_t1: '解锁区块链世界的',
    m_t2: '便捷通道'
  }
}
