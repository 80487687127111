export const routes = {
  RamBox: '/home',
  mintNFT: '/mintNFT',
  vault: '/vault',
  IDO: 'ido',
  Roadmap: '/roadmap',
  Marketplace: '/marketplace',
  Tokenomics: '/tokenomics',
  DAO: '/dao',
  stake: '/stake',
  wallet: '/wallet'
}
