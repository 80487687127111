import { useRAMTokenContract } from './useContract'
import { useSingleCallResult } from '../state/multicall/hooks'
import { useMemo, useCallback } from 'react'
import { useActiveWeb3React } from './index'
import { FIRST_ADDRESS, ZERO_ADDRESS, BAST_TOKEN } from '../constants'
import { isAddress } from '../utils'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../state/transactions/hooks'
import { useI18n } from 'react-simple-i18n'
import { TokenAmount } from 'constants/token'

export function useRambox() {
  const { t } = useI18n()
  const { account } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const contract = useRAMTokenContract()
  const price = useSingleCallResult(contract, '_price', [])?.result
  const balanceOf = useSingleCallResult(contract, 'balanceOf', [account ?? undefined])?.result
  const totalSupply = useSingleCallResult(contract, 'totalSupply', [])?.result
  const initialNumber = useSingleCallResult(contract, '_initialNumber', [])?.result
  const mostCountPerAccount = useSingleCallResult(contract, '_mostCountPerAccount', [])?.result
  const mintActive = useSingleCallResult(contract, '_mintActive', [])?.result

  const mint = useCallback(
    async (count: string | number) => {
      if (!account) throw new Error('none account')
      if (!contract) throw new Error('none contract')
      console.log(count, 'mint')

      const args = [count]
      const method = 'mint'
      console.log('🚀', args, method)

      return contract.estimateGas[method](...args).then(() => {
        return contract[method](...args, {
          // gasLimit: calculateGasMargin(estimatedGasLimit),
          from: account
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: t('save')
          })
          return response.hash
        })
      })
    },
    [account, addTransaction, contract, t]
  )
  return {
    price: price?.[0].toString(),
    balanceOf: balanceOf?.[0].toString(),
    totalSupply: totalSupply?.[0].toString(),
    initialNumber: initialNumber?.[0].toString(),
    mostCountPerAccount: mostCountPerAccount?.[0].toString(),
    mintActive: mintActive?.[0],
    mint
  }
}

export function useGetTotalCost(count: string | number): TokenAmount | undefined {
  const contract = useRAMTokenContract()
  const { chainId } = useActiveWeb3React()
  console.log(count)

  const res = useSingleCallResult(contract, '_price', [])
  if (res) {
    console.log(res.result?.[0].toString(), '_price')
  }
  return useMemo(() => {
    if (!res.result?.[0] || !chainId) {
      return undefined
    }
    return new TokenAmount(BAST_TOKEN[chainId], res.result?.[0].toString())
  }, [chainId, res.result])
}

export function useAvailableAddress(address: string | undefined) {
  const contract = useRAMTokenContract()
  const addr = isAddress(address)
  const userInfo = useSingleCallResult(contract, 'userInfoOf', [addr ? addr : undefined])
  return useMemo(() => {
    if (address?.toLowerCase() === FIRST_ADDRESS.toLowerCase()) return { loading: false, able: true }
    if (!addr) return { loading: false, able: false }
    if (!userInfo?.result) return { loading: false, able: true }
    return { loading: userInfo.loading, able: userInfo.result.refAddress.toLowerCase() !== ZERO_ADDRESS.toLowerCase() }
  }, [addr, address, userInfo.loading, userInfo.result])
}
