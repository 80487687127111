import { Box } from '@mui/material'
import Divider from 'components/Divider'
import React from 'react'
import Modal from '../index'

export default function BindModal({
  children,
  isOpen,
  onDismiss,
  error
}: {
  isOpen: boolean
  onDismiss: () => void
  children: React.ReactNode
  error?: string
}) {
  return (
    <Modal width={'960px'} maxWidth={'960px'} closeIcon customIsOpen={isOpen} customOnDismiss={onDismiss}>
      <Box display="grid" padding="40px" gap="24px" justifyItems="center" width="100%">
        <Divider extension={40} />
        <Box
          style={{
            opacity: !error ? 1 : 0.5
          }}
          display="grid"
          gap="24px"
          width="100%"
        >
          {children}
        </Box>
      </Box>
    </Modal>
  )
}
