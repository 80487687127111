import { Box, Button, Stack, Typography, Grid } from '@mui/material'
import { styled } from '@mui/material'
import { useI18n } from 'react-simple-i18n'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.css'
import 'swiper/modules/autoplay/autoplay.min.css'
import Image from '../../components/Image'
import walletBgImg from '../../assets/images/wallet_bg.png'
import walletAppImg from '../../assets/images/wallet_app_img.png'
import ramBoxText from '../../assets/images/rambox-text.png'
import servicesIcon1 from '../../assets/images/services_icon_1.png'
import servicesIcon2 from '../../assets/images/services_icon_2.png'
import servicesIcon3 from '../../assets/images/services_icon_3.png'
import servicesIcon4 from '../../assets/images/services_icon_4.png'
import servicesIcon5 from '../../assets/images/services_icon_5.png'
import servicesIcon6 from '../../assets/images/services_icon_6.png'

import mdIcon1 from '../../assets/images/md_icon_1.png'
import mdIcon2 from '../../assets/images/md_icon_2.png'
import mdIcon3 from '../../assets/images/md_icon_3.png'

import walletAppLogo from '../../assets/images/wallet_app_logo.png'
// import game from '../../assets/images/game.png'
// import cards from '../../assets/images/cards.png'
// import web from '../../assets/images/web.png'
// import flower from '../../assets/images/flower.png'
// import { PROJECTS } from '../../constants'
// import topBG from '../../assets/images/top-bg.png'
import './index.css'

const TopFrame = styled('div')`
  width: 100%;
  min-width: 500px;
  min-height: 667px;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const BuyButton = styled(Button)(({ theme }) => ({
  width: 152,
  height: 40,
  background: theme.palette.primary.main,
  marginTop: 35,
  border: '1px solid ',
  borderColor: theme.palette.primary.main,
  a: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    fontSize: 12,
    fontFamily: `'Press Start 2P'`
  }
}))

const CnBuyButton = styled(Button)(({ theme }) => ({
  width: 98,
  height: 40,
  background: theme.palette.primary.main,
  marginTop: 35,
  border: '1px solid ',
  borderColor: theme.palette.primary.main,
  a: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    fontSize: 20,
    fontFamily: 'Ark'
  }
}))

// }

// const banners = [topBG]

export default function Wallet() {
  const { t, i18n } = useI18n()
  const mdDataList = [
    {
      icon: mdIcon1,
      desc: t('wallet.sub1')
    },
    {
      icon: mdIcon2,
      desc: t('wallet.sub2')
    },
    {
      icon: mdIcon3,
      desc: t('wallet.sub3')
    }
  ]
  const servicesList = [
    {
      desc: t('wallet.t1'),
      icon: servicesIcon1
    },
    {
      desc: t('wallet.t2'),
      icon: servicesIcon2
    },
    {
      desc: t('wallet.t3'),
      icon: servicesIcon3
    },
    {
      desc: t('wallet.t4'),
      icon: servicesIcon4
    },
    {
      desc: t('wallet.t5'),
      icon: servicesIcon5
    },
    {
      desc: t('wallet.t6'),
      icon: servicesIcon6
    }
  ]
  return (
    <Box p={0} width={'100%'}>
      <TopFrame>
        <Box width={'100%'} sx={{ position: 'absolute', top: 0, right: 0, zIndex: 9 }}>
          <Image
            style={{
              display: 'block',
              marginLeft: 'auto'
            }}
            src={walletBgImg}
          />
          <Box
            height={96}
            width={'100%'}
            sx={{
              backgroundColor: '#000'
            }}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'1248px'}
              height={'100%'}
              margin={'0 auto'}
            >
              {mdDataList.map((item, index) => {
                return (
                  <Box display={'flex'} alignItems={'center'} key={index}>
                    <Image src={item.icon}></Image>
                    <Typography ml={13} fontSize={18} fontWeight={500} fontFamily={'Kanit'} color={'#fff'}>
                      {item.desc}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
        <Box sx={{ position: 'absolute', top: 15, right: 156, zIndex: 10 }}>
          <Image src={walletAppImg} />
        </Box>
        <Box sx={{ position: 'absolute', top: 0, zIndex: 10, marginTop: 172 }}>
          <Box
            sx={{
              display: 'flex',
              minWidth: 1248,
              padding: '0',
              flexDirection: 'column',
              alignItems: 'flex-start',
              borderRadius: '20px',
              justifyContent: 'center',
              zIndex: 10
            }}
          >
            <Image src={walletAppLogo}></Image>
            <Typography
              width={612}
              fontWeight={600}
              lineHeight={'42px'}
              fontFamily={'Kanit'}
              color={'#000'}
              fontSize={36}
              mt={36}
            >
              {t('wallet.desc')}
            </Typography>
            {i18n.getLang() === 'cn' ? (
              <CnBuyButton>
                <a href="https://backend.ramx.tech/download/ramx.apk">{t('wallet.download')}</a>
              </CnBuyButton>
            ) : (
              <BuyButton>
                <a href="https://backend.ramx.tech/download/ramx.apk">{t('wallet.download')}</a>
              </BuyButton>
            )}
          </Box>
        </Box>
      </TopFrame>
      <Typography
        fontFamily={'Kanit'}
        textAlign={'center'}
        lineHeight={'32px'}
        letterSpacing={'1.8px'}
        fontSize={18}
        color={'#000'}
      >
        {t('wallet.title')}
      </Typography>
      <Typography
        fontFamily={'Kanit'}
        textAlign={'center'}
        lineHeight={'32px'}
        fontSize={24}
        color={'#000'}
        fontWeight={700}
        mt={7}
        mb={34}
      >
        {t('wallet.txt')}
      </Typography>
      <Grid margin={'auto'} padding={'0 0'} width={1248} container spacing={23}>
        {servicesList.map((item, index) => {
          return (
            <Grid key={index + 's'} item xs={4}>
              <Box
                sx={{
                  border: '2px solid #000000',
                  boxShadow: '-4px 4px 0px #FEF13F',
                  padding: '27px 12px',
                  height: 164,
                  textAlign: 'center'
                }}
              >
                <Image width={65} height={65} src={item.icon}></Image>
                <Typography
                  mt={10}
                  lineHeight={'20px'}
                  textAlign={'center'}
                  fontFamily={'Kanit'}
                  fontSize={16}
                  color={'#000'}
                >
                  {item.desc}
                </Typography>
              </Box>
            </Grid>
          )
        })}
      </Grid>
      <Box width={1248} sx={{ display: 'flex', margin: '116px auto 0', position: 'relative', zIndex: 10 }}>
        <Stack position={'relative'} width={626} height={365} margin={'auto'}>
          <Typography width={626} fontWeight={700} fontSize={64} color={'#000'} fontStyle={'italic'}>
            {t('wallet.title')}
          </Typography>
        </Stack>
        <Stack width={626} paddingLeft={56}>
          <Typography fontWeight={400} fontSize={16} color={'#000'} lineHeight={'32px'}>
            {t('wallet.content1')}
          </Typography>
          <Typography fontWeight={400} fontSize={16} mt={32} color={'#000'} lineHeight={'32px'}>
            {t('wallet.content2')}
          </Typography>
        </Stack>
      </Box>

      <Box sx={{ width: '100%', marginTop: '-110px', position: 'relative', zIndex: 9 }}>
        <Image width={'100%'} src={ramBoxText} />
      </Box>
      <Typography
        position={'relative'}
        zIndex={10}
        fontSize={16}
        lineHeight={'32px'}
        textAlign={'center'}
        color={'#000'}
      >
        Copyright 2023 - RamBox All right reserved
      </Typography>
    </Box>
  )
}
