import { useState, useCallback } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { AppBar, Box, Stack, styled } from '@mui/material'
// import { ExternalLink } from 'theme/components'
// import Web3Status from './Web3Status'
// import { ShowOnMobile } from 'theme/index'
// import Image from 'components/Image'
// import logo from '../../assets/svg/chain_swap.svg'
import { routes } from 'constants/routes'
import MobileMenu from './MobileMenu'
import Image from '../Image'
// import NetworkSelect from './NetworkSelect'
// import gitbook from '../../assets/images/gitbook.png'
// import telegram from '../../assets/images/telegram.png'
// import telegramDark from '../../assets/images/telegram_dark.png'
// import twitter from '../../assets/images/twitter.png'
// import twitterDark from '../../assets/images/twitter_dark.png'
// import medium from '../../assets/images/medium.png'
// import discord from '../../assets/images/discord.png'
// import discordDark from '../../assets/images/discord_dark.png'
import menu from '../../assets/images/menu-mobile.png'
import menuDark from '../../assets/images/menu-mobile-dark.png'
import menuClose from '../../assets/images/menu-close-mobile.png'
import topImage from '../../assets/images/top-img.png'
// import topImageM from '../../assets/images/top-img-m.png'
// import Lang from './Lang'
// import { scrollToElement } from '../../utils'
import { useI18n } from 'react-simple-i18n'

interface TabContent {
  title: string
  route?: string
  link?: string
  id?: string
  titleContent?: JSX.Element
}

interface Tab extends TabContent {
  subTab?: TabContent[]
}

export function useTabs(): Tab[] {
  const { t } = useI18n()

  return [
    { title: t('Home'), route: routes.RamBox },
    { title: 'MINT NFT', route: routes.mintNFT },
    { title: t('VAULT'), route: routes.vault },
    { title: 'Stake', route: routes.stake }
  ]
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  height: theme.height.header,
  backgroundColor: 'rgba(0, 0, 0, 0)',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: 'none',
  margin: '0 auto -122px',
  zIndex: theme.zIndex.drawer,

  [theme.breakpoints.down('md')]: {
    width: '100%'
  },

  '& .link': {
    textDecoration: 'none',
    fontSize: 14,
    color: '#fff',
    // opacity: 0.5,
    marginRight: 81.5,
    '&.active': {
      opacity: 1,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main
    },
    '&:hover': {
      opacity: 1,
      color: theme.palette.primary.main
    }
  },
  '& .dark': {
    color: '#000',
    '&.active': {
      color: '#000'
    },
    '&:hover': {
      color: '#000'
    }
  },
  '& .lr': {},
  [theme.breakpoints.down('lg')]: {
    '& .link': { marginRight: 15 },
    padding: '0 24px 0 0!important'
  },
  [theme.breakpoints.down('md')]: {
    position: 'fixed'
  },
  [theme.breakpoints.down('sm')]: {
    height: theme.height.mobileHeader,
    padding: '0 20px!important'
  }
}))

const Filler = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    height: theme.height.header,
    display: 'block'
  },
  [theme.breakpoints.down('sm')]: {
    height: theme.height.mobileHeader,
    padding: '0 20px'
  }
}))

const MainLogo = styled(NavLink)(({ theme }) => ({
  '& img': {
    width: 180,
    height: 112
  },
  '&:hover': {
    cursor: 'pointer'
  },
  [theme.breakpoints.down('sm')]: {
    '& img': { width: 137, height: 'auto' },
    marginBottom: -10
  }
}))

// const LinksWrapper = muiStyled('div')(({ theme }) => ({
//   width: '100%',
//   margin: 'auto',
//   display: 'flex',
//   marginLeft: 106,
//   justifyContent: 'space-around',
//   alignItems: 'center',
//   [theme.breakpoints.down('lg')]: {
//     marginLeft: 0
//   }
// }))

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { pathname } = useLocation()
  console.log(pathname, 'pathname', pathname.includes('home'))

  const { t, i18n } = useI18n()
  console.log(t)
  console.log(i18n.getLang(), 'i18n')
  const tabs = useTabs()
  console.log(tabs)

  const handleMobileMenueDismiss = useCallback(() => {
    setTimeout(() => {
      setMobileMenuOpen(false)
    }, 500)
  }, [])

  return (
    <>
      <MobileMenu isOpen={mobileMenuOpen} onDismiss={handleMobileMenueDismiss} />
      <Filler />
      <StyledAppBar>
        <Box display="flex" alignItems="center" justifyContent={'space-between'} width={'100%'}>
          <Box
            sx={{
              opacity: mobileMenuOpen ? '1' : '0'
            }}
          >
            <MainLogo id={'/'} to={'/'}>
              <Image src={topImage} alt={'chainswap'} />
            </MainLogo>
          </Box>

          <Stack
            marginLeft={12}
            onClick={() => {
              setMobileMenuOpen(!mobileMenuOpen)
            }}
          >
            {mobileMenuOpen ? (
              <Image width={47} src={menuClose} />
            ) : pathname.includes('home') ? (
              <Image width={47} src={menu} />
            ) : (
              <Image width={47} src={menuDark} />
            )}
          </Stack>
        </Box>
      </StyledAppBar>
    </>
  )
}
