import { useCallback, useRef } from 'react'
import useUnMount from './useUnMount'

const sleep = (time: number) => {
  return new Promise(resolve => setTimeout(resolve, time))
}

const usePolling = (polling: () => Promise<any>): { doPolling: () => void; cancelPolling: () => void } => {
  const isPollingRef = useRef(false)
  const cancelRef = useRef(false)

  const doPolling = useCallback(() => {
    if (isPollingRef.current) {
      return
    }
    isPollingRef.current = true

    const pollNext = async () => {
      if (cancelRef.current) {
        isPollingRef.current = false
        cancelRef.current = false
        return
      }
      const { hasFinshed } = await polling()

      if (!hasFinshed) {
        await sleep(3000)
        pollNext()
      } else {
        isPollingRef.current = false
      }
    }

    pollNext()
  }, [polling])

  const cancelPolling = useCallback(() => {
    if (isPollingRef.current) {
      cancelRef.current = true
    }
  }, [])

  useUnMount(cancelPolling)

  return { doPolling, cancelPolling }
}

export default usePolling
