export const en = {
  home: {
    title: 'THE FIRST NFTFI AGGREGATION PROTOCOL WHICH ADVOCATES F2P2E (FREE TO PLAY TO EARN)',
    stakingAPY: 'Staking APY',
    holders: 'Number of Holders',
    followers: 'Twitter Followers',
    members: 'Telegram Members',
    video: 'Project Video'
  },
  video: {
    title: 'Project Video'
  },
  projects: {
    title: 'RamBox Products',
    subTitle1: 'GameList',
    subTitle2: 'Marketplace',
    subTitle3: 'DAO',
    content1:
      'The RamBox platform \n' +
      'encourages outstanding NFTFi teams to launch NFTFi projects with high playability and reasonable tokenomics on Gamelist and decides whether to putthem online through DAO (Decentralized self-regulatory organization) voting',
    content2:
      'Users can buy/sell various game NFTs in Marketplace,\n' +
      'including character NFTs, props NFTs, etc. This allows users to enjoy the convenience and wealth effect brought by "game value capitalization"',
    content3:
      'RamBox platform supports RAM token holders to form DAO spontaneously in order to carry out the community governance of RamBox. DAO implements a democratic system of 1 vote for 1 Ram. DAO can decide the following ...'
  },
  tokenomics: {
    title: 'RAM Tokenomics',
    subTitle: 'RAM is the driving force of RamBox ecosystem.',
    text1: '1.Users can stake RAM in RAMSWAP module to get RAM token rewards.',
    text2: '2.Users can stake the LP of RAM and USDT in RAMSWAP module to get RAM token rewards.',
    text3:
      '3.Users can participate in all kinds of games in GameList module. RAM will be built into the economic model of these games.',
    text4:
      '4.RAM can be used as a medium to get RAM token rewards when users buy/sell NFT, stake NFT or produce NFT synthetic asset in Marketplace.',
    totalSupply: 'Total Supply'
  },
  partners: {
    title: ' Investors & Partners',
    price: 'RAM Price',
    buy: 'Buy RAM',
    totalSupply: 'Total Supply:',
    maxSupply: 'Max Supply:',
    tvl: 'Total Value Locked'
  },
  roadmap: {
    title: 'Roadmap',
    r1: 'Q2 2022',
    r2: 'Q3 2022',
    r3: 'Q4 2022',
    r4: 'Q1 2023',
    r5: 'Q2 2023',
    r6: 'Q3 2023',
    t1: 'Team Building',
    t2: 'RamBox Positioning & Vision',
    t3: 'Economic Model',
    t4: 'Website',
    t5: 'WhitePaper',
    t6: 'NFT Design',
    t7: 'RAM IDO',
    t8: 'Launch NFT',
    t9: 'RAM public offering',
    t10: 'NFT Equity Incentive',
    t11: 'Pool',
    t12: 'Rambox DAO',
    t13: 'Ram Chain Beta test',
    t14: 'NFT Equity Community',
    t15: 'Launch Ram Chain',
    t16: 'Node Incentives',
    t17: 'NFT Upgrade',
    t18: 'Ram Chain DAO',
    t19: 'RAM Eco-Fund'
  },
  roadMapV2: {
    title: 'Roadmap',
    r1: 'Q1-Q3 2021',
    r2: 'Q2 2022',
    r3: 'Q3 2022',
    r4: 'Q4 2022',
    r5: 'Q1 2023',
    r6: 'Q2 2023',
    r7: 'Q3-Q4 2023',
    r8: 'Q1-Q2 2024',
    r1_1: 'Initiation of Rambox automated Vault R&D',
    r1_2: 'Launch of Rambox aggregation protocol R&D',
    r1_3: 'Start of Rambox project whitepaper',
    r2_1: 'Completion of core Rambox R&D, achieving over 500% APY',
    r2_2: 'Launch of RAM NETWORK R&D',
    r3_1: 'Preparation for project market launch',
    r3_2: 'Initiation of NFTfi & Gamefi automated yield system design',
    r3_3: 'Start of RAM Commemorative NFT series design',
    r4_1: 'Official launch of the Rambox platform',
    r4_2: 'Launch of Vault feature',
    r5_1: 'Official start of Rambox public chain Vault strategic development',
    r5_2: 'Preparations for Rambox listing on exchanges',
    r6_1: 'Upgrade of Rambox brand strategy',
    r6_2: 'Launch of new aggregation product',
    r6_3: 'Support for BRC20, BRC721 asset transactions',
    r6_4: 'Support for BTC L2 public chain liquidity capture',
    r6_5: 'Launch of Rambox NFT series products',
    r6_6: 'Initiation of global market operations',
    r6_7: 'Launch of unique Rambox wallet application',
    r7_1: 'Deployment of Rambox AI servers globally',
    r7_2: 'Official launch of Rambox full-chain liquidity aggregation feature',
    r7_3: 'Joint construction of Web2 to Web3 liquidity services with RWA companies',
    r8_1: 'Launch of proprietary Rambox public chain system',
    r8_2: 'Opening of Rambox node network',
    r8_3: 'Start of Rambox node staking ecosystem'
  },
  token: 'RAM Token',
  saleRule: 'Fair Launch - Max buy 200 USDT',
  tops: 'Soft/Hard',
  progress: 'Progress',
  left: 'Total left RAM',
  total: 'Total RAM',
  myRAM: 'My RAM',
  lockup: 'Lockup Time',
  start: 'Sale Starts in',
  preAddress: 'Presale Address',
  name: 'Token Name',
  symbol: 'Token Symbol',
  decimals: 'Token Decimals',
  tokenAddress: 'Token Address',
  tip: '(Do not send any asset to the contract address!)',
  supply: 'Total Supply',
  presale: 'Token For Presale',
  cap: 'Initial Market Cap(estimate)',
  soft: 'Soft Cap',
  hard: 'Hard Cap',
  startTime: 'Presale start at',
  endTime: 'Presale end at',
  list: 'Listing on',
  liquidityPercent: 'liquidity Percent',
  liquidityLockupTime: 'liquidity Lockup Time',
  days: '9 Days',
  enterAmount: 'Enter amount',
  max: 'Max amount is 200',
  allow: 'Approve',
  buy: 'Buy',
  approving: 'Approving',
  with: 'with',
  marketplace: 'Marketplace',
  tokenomics1: 'Tokenomics',
  insufficient: 'Insufficient balance',
  End: 'End',
  enterInviter: 'Enter referee wallet address',
  bind: 'Bind',
  notInviter: 'Invalid wallet address',
  inviteLink: 'Promotion',
  totalLock: 'Total Deposit',
  stakedAmount: 'Pledged',
  claimAbleAmount: 'Income',
  apy: 'Compound Annual',
  save: 'Add Deposit',
  withdraw: 'Withdraw',
  claimReward: 'Withdraw',
  exit: 'Withdraw All',
  stakingTip: 'Reminder',
  tip1: '1、The withdrawal of proceeds only includes proceeds. The original RAM deposit will not be withdrawn.',
  tip2: '2、The withdrawal of RAM only includes the original RAM deposit.',
  tip3: '3、The withdrawal of RAM and proceeds includes both the RAM deposit and the proceeds.',
  attention: 'Attention',
  tip4:
    'After depositing RAM, and the proceeds will be allocated according to the RAM amount shown in the snapshots.10% of the proceeds will be charged as transaction fee (the withdrawal of the RAM deposit is free of charge) and will be burned later. Block browser for transaction fee burning:\n',
  balance: 'balance',
  Max: 'Max',
  contribution: 'Contribution',
  myInviteLink: 'My Invitation Link:',
  VAULT: 'VAULT',
  Home: 'Home',
  gotovault: 'GO TO VAULT',
  entervault: 'ENTER THE VAULT',
  productDesc1: `The NFTfi Vault is a liquidity aggregation protocol focusing on NFTfi. It is an automated NFTfi yield machine
  gun pool operating system. Rambox is an asset management protocol specializing in the NFT field, aiming to
  build infrastructure for a future reliant on automated investments. It focuses on maximizing returns by
  automatically switching between different NFTfi protocols. Unlike traditional NFTfi, Rambox is active in
  various potential, profitable NFT projects and NFT liquidity services. Currently, Rambox supports multiple
  NFTfi products and cross-chain ecosystems.`,
  productDesc3: `All users holding RAM will be able to participate in the digital technology services provided by Rambox and
  become validator nodes within the network. Each participating management and operational validator node will
  directly benefit from the entire project's revenue, with the scale of returns being positively correlated with
  the number of validator nodes participated in by the user. Moreover, the role of Rambox Network will replace
  the treasury, and a portion of all Rambox platform profits will be fully replaced by the Rambox Network. Validator nodes in Rambox's digital technology services will become part of the largest decentralized NFT network ever, with only 30,000 nodes ever, requiring a pledge of 100,000 RAM to secure validator node qualification and obtain all vault earnings.`,
  NFTfiVault: 'NFTfi Vault',
  ContributorNFT: 'Contributor NFT',
  RamboxNetwork: 'Rambox Network',
  prod3_1: 'Modular Blockchain',
  prod3_2: 'Cloud-based Servers',
  prod3_3: 'EVM Support',
  prod3_4: 'Cross-chain' + '\n' + 'Connectivity',
  comingSoon: 'COMING SOON',
  prod2_1: 'Basic Computing Power',
  prod2_2: 'Value Growth',
  prod2_3: 'DAO Governance',
  prod2_4: 'Appreciation Potential',
  prod2_5: ' RAM Upgrade',
  prod2_1_desc:
    'Each Contributor NFT will possess a certain amount of mining pool computing power and will also receive additional contribution value boosts.',
  prod2_2_desc:
    'The earning capability of Contributor NFTs is influenced by the eco-contribution value of the NFT holder.',
  prod2_3_desc: 'Contributor NFT holders can obtain the authority to participate in future DAO proposals.',
  prod2_4_desc: 'Contributor NFT have unlimited appreciation potential in the secondary market.',
  prod2_5_desc: `Contributor NFT holders' RAM tokens will be upgraded to Al computing power assets, enabling them to enjoy additional benefits.`,
  romBox_title: 'What is Rambox?',
  romBox_desc: `Rambox will focus on exploring all aspects of AI commercialization in the digital technology domain, with
  the mission of building the world's strongest, most simplified stable investment income platform. Currently,
  it is concentrating on NFTfi, derivatives, public chain funds, and other liquidity service providers,
  leveraging AI-powered automated investment strategies. In the future, Rambox will prioritize the development
  of NFTfi and modular public chain infrastructure.`,
  CSA: 'Current staking APY...',
  InPa: 'Investors & Partners',
  ramIs: 'IS THE DRIVING FORCE OF',
  ramIs_2: 'RAMBOX ECOSYSTEM',
  ramIs_desc_1: 'Vault Staking Rewards',
  ramIs_desc_2: 'NFT Interaction',
  ramIs_desc_3: 'Participation in' + '\n' + 'Rambox Network',
  ramIs_desc_4: 'Community DAO' + '\n' + 'Governance',
  yInfo: 'YOUR INFORMATION',
  AnnualCompound: 'Annual Compound',
  get: 'GET',
  mintNFT_desc:
    'Contributor NFT not only holds a specific amount of mining pool computing power, but also benefits from additional eco-contribution value boosts, influencing its earning capacity. Contributor NFT offer owners the right to participate in DAO proposals and harbor unlimited appreciation potential in secondary markets. RAM tokens held by Contributor NFT holders are upgraded to AI computing power assets, enjoying additional benefits.',
  mintNFT_1: '1. Basic Computing Power',
  mintNFT_2: '2. Value Growth',
  mintNFT_3: '3. DAO Governance',
  mintNFT_4: '4. Appreciation Potential',
  mintNFT_5: '5. RAM Upgrade',
  mintNFT_1_desc:
    'Each Contributor NFT will possess a certain amount of mining pool computing power and will also receive additional contribution value boosts.',
  mintNFT_2_desc:
    'The earning capability of Contributor NFTs is influenced by the eco-contribution value of the NFT holder.',
  mintNFT_3_desc: 'Contributor NFT holders can obtain the authority to participate in future DAO proposals.',
  mintNFT_4_desc: 'Contributor NFT have unlimited appreciation potential in the secondary market.',
  mintNFT_5_desc: `Contributor NFT holders' RAM tokens will be upgraded to Al computing power assets, enabling them to enjoy additional benefits.`,
  nft: {
    name: 'NFT Name',
    price: 'Price',
    Quantity: 'Quantity',
    Amount: 'Amount'
  },
  close: 'Close',
  change: 'Change',
  transactionsTips: 'Your transactions will appear here...',
  copyAdd: 'Copy Address',
  tbd: 'TBD',
  stake: 'stake',
  STAKE: 'STAKE',
  totalRevenue: 'Total Revenue',
  claimed: 'Claimed',
  claim: 'CLAIM',
  reward: 'Reward',
  signer: 'Signer',
  wallet: {
    download: 'Download',
    desc: 'Unlocking a Convenient Gateway to the Blockchain World',
    sub1: 'Aggregated Asset Management',
    sub2: 'All-in-One Web3 Experience',
    sub3: 'AI-Powered Asset Growth',
    title: 'RAMX WALLET',
    txt: 'All-in-One Web3 Asset Management and Financial Services',
    t1: 'Support for multi-chain assets.',
    t2: 'Management of multiple wallets.',
    t3: 'Asset market tracking and historical records.',
    t4: 'Integration with the Ethereum Name Service (ENS).',
    t5: 'Interaction with decentralized applications (DApps).',
    t6: 'Support for instant exchanges and zero-GAS transactions.',
    content1:
      'RamX is a simplified asset management platform developed by Rambox, built upon the core features of Rambox.It allows users to streamline account and asset management, and offers additional features such as DEX swaps, cross-chain transactions, and one-stop liquidity provision.',
    content2:
      'RamX simplifies Web3 usage, serving as a user-friendly gateway to the Web3 ecosystem. It also offers a one-stop solution for managing crypto assets and exploring liquidity-based investment products.',
    m_t1: 'Unlocking a Convenient Gateway to the',
    m_t2: 'Blockchain World'
  }
}
