import { Box, styled, Typography } from '@mui/material'
// import { Box, Stack, styled, Tooltip, Typography } from '@mui/material'
// import lang from '../../assets/images/lang.png'
// import Image from '../Image'
import { useState } from 'react'
import { useI18n } from 'react-simple-i18n'
// import { isMobile } from 'react-device-detect'

const LangItem = styled(Typography)(({}) => ({
  width: 42,
  textAlign: 'center',
  lineHeight: '28px',
  cursor: 'pointer',
  borderRadius: 10,
  color: '#000',
  fontSize: 14,
  '&:hover': {
    backgroundColor: '#fef13f',
    color: '#000'
  },
  '&.active': {
    backgroundColor: '#fef13f',
    color: '#000'
  }
  // width: 42px;
  // height: 29px;
  // text-align: center;
  // line-height: 29px;
  // cursor: pointer;
  // border-radius: 10px;
  // color: #000;
  // font-size: 14px;
  // '&:hover': {
  //   opacity: 1
  // }
}))

export default function Lang() {
  const [open, setOpen] = useState(false)
  console.log(open)
  const { i18n } = useI18n()

  return (
    <Box
      margin={'0 20px 0 0'}
      padding={'0 4px'}
      sx={{
        height: 39,
        width: 98,
        border: '1px solid #FEF13F',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <LangItem
        className={i18n.getLang() === 'en' ? 'active' : ''}
        onClick={() => {
          setOpen(false)
          const body = document.getElementsByTagName('body')[0]
          body.className = 'en-theme'
          i18n.setLang('en')
        }}
      >
        EN
      </LangItem>
      <LangItem
        className={i18n.getLang() === 'cn' ? 'active' : ''}
        onClick={() => {
          setOpen(false)
          const body = document.getElementsByTagName('body')[0]
          body.className = 'cn-theme'
          i18n.setLang('cn')
        }}
      >
        CN
      </LangItem>
      {/* <Tooltip
        open={open}
        title={
          <Stack spacing={8} padding={8}>
            <LangItem
              onClick={() => {
                setOpen(false)
                i18n.setLang('cn')
              }}
            >
              中文
            </LangItem>
            <LangItem
              onClick={() => {
                setOpen(false)
                i18n.setLang('en')
              }}
            >
              English
            </LangItem>
          </Stack>
        }
      >
        <Stack>
          <Image
            width={isMobile ? 18 : 32}
            onClick={() => {
              setOpen(!open)
            }}
            src={lang}
          />
        </Stack>
      </Tooltip> */}
    </Box>
  )
}
