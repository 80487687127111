import { Stack, styled, Typography, Box } from '@mui/material'
import { isMobile } from 'react-device-detect'
import mintNFTBg from '../../assets/images/mintNFT-bg.png'
import Image from '../../components/Image'
import mintNFTImg from '../../assets/images/book-h.png'
import bookBg from '../../assets/images/book-bg.png'
import { useI18n } from 'react-simple-i18n'
import Button from 'components/Button/Button'
import { useActiveWeb3React } from '../../hooks'
import { useRambox, useGetTotalCost } from '../../hooks/useRambox'
import { useState, useCallback, useMemo } from 'react'
import TransactionSubmittedModal from '../../components/Modal/TransactionModals/TransactiontionSubmittedModal'
import TransactionPendingModal from 'components/Modal/TransactionModals/TransactionPendingModal'
import useModal from '../../hooks/useModal'
import MessageBox from '../../components/Modal/TransactionModals/MessageBox'
import { useApproveCallback } from 'hooks/useApproveCallback'
import Web3 from 'web3'
import { useWalletModalToggle } from 'state/application/hooks'
import { RAMNFT } from '../../constants'
import moment from 'moment'
import useCountdown from 'hooks/useCountdown'

const IDOFrame = styled('div')`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  padding-top: 149px;
  min-height: 100vh;
`
const Title = styled(Typography)`
  font-size: 64px;
  font-family: 'Kanit';
  font-style: italic;
  color: #000;
  font-weight: 700;
  margin-right: auto;
  margin-bottom: 28px;
`

export default function MintNFT() {
  const toggleWalletModal = useWalletModalToggle()
  const { t } = useI18n()
  const { account, chainId } = useActiveWeb3React()
  const { showModal, hideModal } = useModal()
  const [count, setCount] = useState(1)

  const { price, mostCountPerAccount, mint, mintActive } = useRambox()
  console.log(mintActive, 'mintActive')
  const closeTime = new Date('2023-07-28 20:30:00').getTime()
  const startTime = new Date('2023-07-28 20:00:00').getTime()
  const totalCost = useGetTotalCost(count)
  const [buyApprovalState, buyApprovalCallback] = useApproveCallback(totalCost, RAMNFT[chainId ?? 56])

  const mintCallback = useCallback(async () => {
    if (!account || !count) return
    showModal(<TransactionPendingModal />)
    mint(count)
      .then(() => {
        hideModal()
        showModal(<TransactionSubmittedModal />)
      })
      .catch((err: any) => {
        hideModal()
        showModal(
          <MessageBox type="error">{err.error && err.error.message ? err.error.message : err?.message}</MessageBox>
        )
        console.error(err)
      })
  }, [account, count, showModal, mint, hideModal])

  const list = [
    {
      title: t('mintNFT_1'),
      desc: t('mintNFT_1_desc')
    },
    {
      title: t('mintNFT_2'),
      desc: t('mintNFT_2_desc')
    },
    {
      title: t('mintNFT_3'),
      desc: t('mintNFT_3_desc')
    },
    {
      title: t('mintNFT_4'),
      desc: t('mintNFT_4_desc')
    },
    {
      title: t('mintNFT_5'),
      desc: t('mintNFT_5_desc')
    }
  ]

  const deadline = useMemo(
    () =>
      moment('2023-07-28 20:00:00')
        // 设置时间
        .format('YYYY-MM-DD HH:mm:ss'),
    []
  )
  const { hour, minute, second } = useCountdown({
    deadline
  })
  const NFTButtom = useMemo(() => {
    if (new Date().getTime() > closeTime || (new Date().getTime() > startTime && !(mintActive === true))) {
      return (
        <>
          <Button
            disabled
            onClick={() => {}}
            style={{
              width: '495px',
              height: '47px'
            }}
          >
            End
          </Button>
        </>
      )
    }
    if (!account) {
      return (
        <>
          <Button
            disabled={!(hour === 0 && minute === 0 && second === 0)}
            onClick={toggleWalletModal}
            style={{
              width: '495px',
              height: '47px'
            }}
          >
            Connect Wallet
          </Button>
        </>
      )
    }

    if (!totalCost) {
      return (
        <>
          <Button
            disabled
            style={{
              width: '495px',
              height: '47px'
            }}
          >
            Loading
          </Button>
        </>
      )
    }
    if (buyApprovalState !== 3) {
      if (buyApprovalState === 2) {
        return (
          <>
            <Button
              disabled
              style={{
                width: '495px',
                height: '47px'
              }}
            >
              Approving
            </Button>
          </>
        )
      } else if (buyApprovalState === 1) {
        return (
          <>
            <Button
              disabled={!(hour === 0 && minute === 0 && second === 0)}
              onClick={buyApprovalCallback}
              style={{
                width: '495px',
                height: '47px'
              }}
            >
              Approval
            </Button>
          </>
        )
      } else {
        return (
          <>
            <Button
              style={{
                width: '495px',
                height: '47px'
              }}
              disabled
            >
              Loading...
            </Button>
          </>
        )
      }
    }

    return (
      <>
        <Button
          onClick={mintCallback}
          disabled={!(hour === 0 && minute === 0 && second === 0)}
          style={{
            width: '495px',
            height: '47px'
          }}
        >
          Mint
        </Button>
      </>
    )
  }, [
    account,
    buyApprovalState,
    totalCost,
    mintCallback,
    buyApprovalCallback,
    toggleWalletModal,
    hour,
    minute,
    second,
    closeTime,
    startTime,
    mintActive
  ])

  function NFTButtomFun() {
    return NFTButtom
  }
  return (
    <IDOFrame
      sx={{
        width: isMobile ? '100%' : '1248px'
      }}
    >
      <Stack
        sx={{
          position: 'relative',
          zIndex: 10
        }}
        width={'100%'}
        direction={'row'}
        justifyContent={'space-between'}
      >
        <Stack spacing={20}>
          <Title>Mint NFT</Title>
          <Stack direction={'row'} spacing={70}>
            <Typography width={90} fontSize={16} lineHeight={'32px'}>
              {t('nft.name')}
            </Typography>
            <Typography fontWeight={700} fontSize={24} lineHeight={'32px'}>
              Contributor NFT
            </Typography>
          </Stack>
          <Stack direction={'row'} spacing={70}>
            <Typography width={90} fontSize={16} lineHeight={'32px'}>
              {t('nft.price')}
            </Typography>
            <Typography fontWeight={700} fontSize={24} lineHeight={'32px'}>
              {price ? Web3.utils.fromWei(price, 'ether') : '--'} $USDT
            </Typography>
          </Stack>
          <Stack direction={'row'} spacing={70}>
            <Typography width={90} fontSize={16} lineHeight={'32px'}>
              {t('nft.Quantity')}
            </Typography>

            {new Date().getTime() > closeTime || (new Date().getTime() > startTime && !(mintActive === true)) ? (
              <Typography fontWeight={700} fontSize={24} lineHeight={'32px'}>
                --
              </Typography>
            ) : (
              <Typography fontWeight={700} fontSize={24} lineHeight={'32px'}>
                300
              </Typography>
            )}
          </Stack>
          <Stack direction={'row'} spacing={70}>
            <Typography width={90} fontSize={16} lineHeight={'43px'}>
              {t('nft.Amount')}
            </Typography>
            <Stack
              direction={'row'}
              sx={{
                width: 227,
                height: 43,
                border: '2px solid #FEF13F'
              }}
            >
              <Button
                disabled={
                  !(hour === 0 && minute === 0 && second === 0) ||
                  count == 1 ||
                  new Date().getTime() > closeTime ||
                  (new Date().getTime() > startTime && !(mintActive === true))
                }
                onClick={() => setCount(count - 1)}
                style={{
                  width: 43,
                  height: 39,
                  background: '#fef13f',
                  fontSize: 40,
                  textAlign: 'center',
                  cursor: 'pointer',
                  borderRadius: 0
                }}
              >
                -
              </Button>
              <Box
                sx={{
                  flex: 1,
                  textAlign: 'center',
                  lineHeight: '39px',
                  fontSize: 24
                }}
              >
                {count}
              </Box>
              <Button
                onClick={() => setCount(count + 1)}
                disabled={
                  !(hour === 0 && minute === 0 && second === 0) ||
                  count == mostCountPerAccount ||
                  new Date().getTime() > closeTime ||
                  (new Date().getTime() > startTime && !(mintActive === true))
                }
                style={{
                  width: 43,
                  height: 39,
                  background: '#fef13f',
                  fontSize: 40,
                  textAlign: 'center',
                  cursor: 'pointer',
                  borderRadius: 0
                }}
              >
                +
              </Button>
            </Stack>
          </Stack>
          <NFTButtomFun />
          {!(hour === 0 && minute === 0 && second === 0) && (
            <Box
              sx={{
                textAlign: 'center',
                border: '1px solid #FEF13F',
                height: 45,
                fontSize: 22,
                lineHeight: '45px',
                borderRadius: '10px',
                width: '100%',
                margin: '20px auto 0!important'
              }}
            >
              {hour < 10 ? '0' + hour : hour}:{minute < 10 ? '0' + minute : minute}:
              {second < 10 ? '0' + second : second}
            </Box>
          )}
        </Stack>
        <Stack
          sx={{
            position: 'relative'
          }}
          width={600}
        >
          <Image src={bookBg}></Image>
          <Image
            style={{
              position: 'absolute',
              width: '556px',
              height: '371px',
              left: '22px',
              top: '36px'
            }}
            src={mintNFTImg}
          ></Image>
        </Stack>
      </Stack>
      <Stack
        sx={{
          position: 'relative',
          zIndex: 10
        }}
        spacing={28}
        direction={'row'}
        marginTop={90}
        width={'100%'}
      >
        <Stack width={'50%'}>
          <Typography
            sx={{
              borderLeft: '5px solid #FEF13F'
            }}
            paddingLeft={34}
            fontSize={18}
            fontWeight={600}
            lineHeight={'32px'}
          >
            {t('mintNFT_desc')}
          </Typography>
        </Stack>
        <Stack width={'50%'} spacing={20}>
          {list.map((item, index) => {
            return (
              <Box sx={{}} key={index + 's'}>
                <Typography fontSize={16} fontWeight={700} lineHeight={'24px'}>
                  {item.title}
                </Typography>
                <Typography fontSize={16} fontWeight={400} lineHeight={'24px'}>
                  {item.desc}
                </Typography>
              </Box>
            )
          })}
        </Stack>
      </Stack>
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 2,
          height: 'calc(100% - 8px)'
        }}
      >
        <Image height={'100%'} src={mintNFTBg}></Image>
      </Box>
    </IDOFrame>
  )
}
