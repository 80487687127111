import { usePancakeswapContract } from './useContract'
import { useSingleCallResult } from '../state/multicall/hooks'

export function usePancakeswap() {
  const contract = usePancakeswapContract()
  const amounts = useSingleCallResult(contract, 'getAmountsOut', [
    '1000000000000000000000',
    ['0x76D9A7c6164DE3bC4d95ef0c011D3D22EB8b4d60', '0x55d398326f99059fF775485246999027B3197955']
  ])?.result

  return {
    amounts: amounts?.[0]
  }
}
