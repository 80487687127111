// 组件卸载时的钩子，componentWillUnMount

import { useEffect } from 'react'

const useUnMount = (fn: () => void): void => {
  useEffect(
    () => () => {
      fn()
    },
    [fn]
  )
}

export default useUnMount
