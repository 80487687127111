import { Box, Button, Stack, Typography, Grid } from '@mui/material'
import { styled } from '@mui/material'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.css'
import 'swiper/modules/autoplay/autoplay.min.css'
import Image from '../../components/Image'
import bannerImage from '../../assets/images/wallet_app_m_img.png'
import walletAppLogo from '../../assets/images/wallet_app_logo_m.png'
import bottomBg from '../../assets/images/RAMBOX.png'
import topImage from '../../assets/images/top-img.png'
import topBG from '../../assets/images/wallet_m_bg.png'
import { NavLink } from 'react-router-dom'
import './index.css'
import { useI18n } from 'react-simple-i18n'
import mdIcon1 from '../../assets/images/md_icon_m_1.png'
import mdIcon2 from '../../assets/images/md_icon_m_2.png'
import mdIcon3 from '../../assets/images/md_icon_m_3.png'
import servicesIcon1 from '../../assets/images/services_icon_1.png'
import servicesIcon2 from '../../assets/images/services_icon_2.png'
import servicesIcon3 from '../../assets/images/services_icon_3.png'
import servicesIcon4 from '../../assets/images/services_icon_4.png'
import servicesIcon5 from '../../assets/images/services_icon_5.png'
import servicesIcon6 from '../../assets/images/services_icon_6.png'

const TopFrame = styled('div')`
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${topBG});
  background-size: 100%;
  background-repeat: no-repeat;
`

const Title = styled(Typography)`
  font-size: 40px;
  font-family: 'Kanit';
  font-style: italic;
  color: #000;
  font-weight: 700;
  line-height: 50px;
`

const BuyButton = styled(Button)(({ theme }) => ({
  width: 152,
  height: 40,
  background: theme.palette.primary.main,
  color: theme.palette.text.primary,
  fontSize: 12,
  border: '1px solid ',
  borderColor: theme.palette.primary.main,
  fontFamily: `'Press Start 2P'`,
  lineHeight: '40px'
}))

const CnBuyButton = styled(Button)(({ theme }) => ({
  width: 98,
  height: 40,
  background: theme.palette.primary.main,
  color: theme.palette.text.primary,
  fontSize: 20,
  border: '1px solid ',
  borderColor: theme.palette.primary.main,
  fontFamily: 'Ark',
  lineHeight: '40px'
}))

// const banners = [topBG]
const MainLogoPage = styled(NavLink)(({ theme }) => ({
  position: 'absolute',
  left: 20,
  top: -3,
  '& img': {
    width: 180,
    height: 112
  },
  '&:hover': {
    cursor: 'pointer'
  },
  [theme.breakpoints.down('sm')]: {
    '& img': { width: 137, height: 'auto' },
    marginBottom: -10
  }
}))
export default function Wallet() {
  const { t, i18n } = useI18n()
  const mdDataList = [
    {
      icon: mdIcon1,
      desc: t('wallet.sub1')
    },
    {
      icon: mdIcon2,
      desc: t('wallet.sub2')
    },
    {
      icon: mdIcon3,
      desc: t('wallet.sub3')
    }
  ]
  const servicesList = [
    {
      desc: t('wallet.t1'),
      icon: servicesIcon1
    },
    {
      desc: t('wallet.t2'),
      icon: servicesIcon2
    },
    {
      desc: t('wallet.t3'),
      icon: servicesIcon3
    },
    {
      desc: t('wallet.t4'),
      icon: servicesIcon4
    },
    {
      desc: t('wallet.t5'),
      icon: servicesIcon5
    },
    {
      desc: t('wallet.t6'),
      icon: servicesIcon6
    }
  ]
  return (
    <Box p={0} width={'100%'}>
      <MainLogoPage id={'/'} to={'/'}>
        <Image src={topImage} alt={'chainswap'} />
      </MainLogoPage>

      <TopFrame>
        <Stack
          sx={{
            background: '#000'
          }}
          height={350}
        >
          <Box sx={{ position: 'absolute', top: 32, left: '50%', zIndex: 9, transform: 'translateX(-50%)' }}>
            <Image width={211} src={bannerImage} />
          </Box>
        </Stack>

        <Box sx={{ padding: '0 25px' }}>
          <Box
            sx={{
              display: 'flex',
              minWidth: '100%',
              padding: '0',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: '20px',
              justifyContent: 'center',
              zIndex: 10
            }}
          >
            <Image width={245} src={walletAppLogo}></Image>
            <Typography
              mt={36}
              lineHeight={'36px'}
              fontSize={24}
              fontWeight={700}
              fontFamily={'Kanit'}
              textAlign={'center'}
              sx={{
                textTransform: 'uppercase'
              }}
            >
              {t('wallet.m_t1')}
            </Typography>
            <Typography
              mb={38}
              lineHeight={'36px'}
              fontSize={24}
              fontWeight={700}
              fontFamily={'Kanit'}
              textAlign={'center'}
              sx={{
                textTransform: 'uppercase'
              }}
            >
              {t('wallet.m_t2')}
            </Typography>
            {i18n.getLang() === 'cn' ? (
              <CnBuyButton onClick={() => (window.location.href = 'https://backend.ramx.tech/download/ramx.apk')}>
                {t('wallet.download')}
              </CnBuyButton>
            ) : (
              <BuyButton onClick={() => (window.location.href = 'https://backend.ramx.tech/download/ramx.apk')}>
                {t('wallet.download')}
              </BuyButton>
            )}
          </Box>
        </Box>
      </TopFrame>
      <Box
        sx={{
          backgroundColor: '#000'
        }}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        width={'100%'}
        padding={'25px'}
        mt={48}
      >
        {mdDataList.map((item, index) => {
          return (
            <Box textAlign={'center'} key={index}>
              <Image width={30} src={item.icon}></Image>
              <Typography
                textAlign={'center'}
                lineHeight={'14px'}
                fontSize={12}
                fontWeight={500}
                fontFamily={'Kanit'}
                width={108}
                color={'#fff'}
                mt={13}
              >
                {item.desc}
              </Typography>
            </Box>
          )
        })}
      </Box>
      <Typography
        fontFamily={'Kanit'}
        textAlign={'center'}
        lineHeight={'32px'}
        letterSpacing={'1.8px'}
        fontSize={18}
        mt={37}
        color={'#000'}
      >
        {t('wallet.title')}
      </Typography>
      <Typography
        width={364}
        fontFamily={'Kanit'}
        textAlign={'center'}
        lineHeight={'32px'}
        fontSize={24}
        color={'#000'}
        fontWeight={700}
        margin={'7px auto 24px'}
      >
        {t('wallet.txt')}
      </Typography>
      <Grid padding={'0 25px'} width={'100%'} container spacing={9}>
        {servicesList.map((item, index) => {
          return (
            <Grid key={index + 's'} item xs={4}>
              <Box
                sx={{
                  border: '2px solid #000000',
                  boxShadow: '-4px 4px 0px #FEF13F',
                  padding: '27px 0',
                  height: '100%',
                  textAlign: 'center'
                }}
              >
                <Image width={50} height={50} src={item.icon}></Image>
                <Typography
                  mt={10}
                  lineHeight={'20px'}
                  textAlign={'center'}
                  fontFamily={'Kanit'}
                  fontSize={12}
                  color={'#000'}
                >
                  {item.desc}
                </Typography>
              </Box>
            </Grid>
          )
        })}
      </Grid>
      <Box sx={{ width: '100%', position: 'relative', padding: '70px 25px 70px' }}>
        <Box sx={{ width: '100%', margin: '0px 0 42px 0' }}>
          <Title textAlign={'left'}>{t('wallet.title')}</Title>
        </Box>
        <Typography fontWeight={400} fontSize={16} color={'#000'} lineHeight={'32px'}>
          {t('wallet.content1')}
        </Typography>
        <Typography fontWeight={400} fontSize={16} mt={32} color={'#000'} lineHeight={'32px'}>
          {t('wallet.content2')}
        </Typography>
      </Box>

      {/* Investors & Partners */}
      <Box
        sx={{
          background: `url(${bottomBg}) no-repeat`,
          paddingTop: 45,
          paddingBottom: 43,
          backgroundSize: '100%',
          paddingLeft: 25,
          paddingRight: 25
        }}
      >
        <Typography fontSize={16} lineHeight={'32px'} mt={15} textAlign={'center'} color={'#000'}>
          Copyright 2023 - RamBox All right reserved
        </Typography>
      </Box>
    </Box>
  )
}
